import { useState } from "react"

export default function OrderTracking() {
    const [orderNumber, setOrderNumber] = useState("")
    const [orderStatus, setOrderStatus] = useState([])
    const checkStatus = async (e) => {
        e.preventDefault()
        const orderError = document.getElementById("orderError")
        let hasError = false;
        if (orderNumber === "") {
            orderError.innerText = "please enter order number"
            hasError = true
        } else {
            orderError.innerText = ""
        }
        if (hasError) {
            return;
        }
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/orderByNumber/${orderNumber}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        if (data.message === "order not found") {
            orderError.innerText = "Order not found"
        }
        if (res.ok) {
            setOrderStatus(data);
        }
    }
    return (
        <>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Order Tracking</h1>
                </div>
            </div>
            {/* page header end */}

            {/* content start */}
            <div className="container pt-3">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="text-danger mb-2" id="orderError"></div>
                        <form onSubmit={checkStatus}>
                            <input type="text" placeholder="Order Tracking Number" className="form-control" name="orderNumber" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-danger mt-4" type="submit">Check Order Status</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row py-6">
                    <div className="col-md-12 table-responsive">
                        {orderStatus && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Order Number</th>
                                        <th>Order Amount</th>
                                        <th>Order Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderStatus && (
                                        <tr>
                                            <td>{orderStatus.orderNumber}</td>
                                            <td>{orderStatus.orderAmount}</td>
                                            <td>{orderStatus.orderStatus}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}
