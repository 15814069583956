import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MyContext from '../ContextApi/MyCOntext'

export default function SignIn() {
    const { setSignUser } = useContext(MyContext)
    const navigate = useNavigate()
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    const signInFunc = async (e) => {
        e.preventDefault()
        const emailError = document.getElementById("emailError")
        const passwordError = document.getElementById("passwordError")
        const { email, password } = user
        let emptyFieldError = false

        if (!email) {
            emailError.innerText = "Please enter email"
            emptyFieldError = true
        } else {
            emailError.innerText = ""
        }

        if (!password) {
            passwordError.innerText = "Please enter password"
            emptyFieldError = true
        } else {
            passwordError.innerText = ""
        }

        if (emptyFieldError) {
            return;
        }

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/signIn", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, password })
        })
        const data = await res.json()
        if (data.message === "Not found any user with this email") {
            emailError.innerText = "Not found any user with this email"
        } else if (data.message === "Not found any user with this password") {
            passwordError.innerText = "Not found any user with this password"
        }
        sessionStorage.setItem("signUp", JSON.stringify(data))
        setSignUser(data)
        if (data.role === "shopManager") {
            navigate("/shop-manager")
        } else if (data.role === "customer") {
            navigate("/order-detail")
        } else if (data.role === "admin") {
            navigate("/admin-panel")
        }
    }

    const onchange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Sign In</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container py-3">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <form onSubmit={signInFunc}>

                            <input type="email" className='form-control mt-3' placeholder='Email' name='email' value={user.email} onChange={onchange} />
                            <div id="emailError" className='text-danger' ></div>

                            <input type="password" className='form-control mt-3' placeholder='Password' name='password' value={user.password} onChange={onchange} />
                            <div id="passwordError" className='text-danger' ></div>

                            <div className='d-flex justify-content-center mt-3'>
                                <button type='submit' className='btn btn-danger'>Sign In</button>
                            </div>
                        </form>
                        <h6 className='mt-3 text-center'>Not registered Yet? <Link to="/signUp" style={{ textDecoration: "underline" }}> Sign Up</Link></h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
