import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import AdminNavbar from '../Components/AdminNavbar'

export default function Adminpanel() {
    return (
        <div>
            <AdminNavbar />
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Galaxify : Admin Panel</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <div>
                            <Link to="users"> <button className='btn btn-outline-danger w-100'>Users</button> </Link>
                        </div>
                        <div className='mt-3'>
                            <Link to="add-category"> <button className='btn btn-outline-danger w-100'>Category</button> </Link>
                        </div>
                        <div className='mt-3'>
                            <Link to="add-product"> <button className='btn btn-outline-danger w-100'>Products</button> </Link>
                        </div>
                        <div className='mt-3'>
                            <Link to="orders"> <button className='btn btn-outline-danger w-100'>Orders</button> </Link>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
