import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import AddProducts from './Adminpanel/AddProducts';
import Navbar from './Components/Navbar';
import SignUp from './Components/SignUp';
import SignIn from './Components/SignIn';
import Adminpanel from './Adminpanel/Aminpanel';
import MyProvider from './ContextApi/MyProvider';
import AddCategory from './Adminpanel/AddCategory';
import Users from './Adminpanel/Users';
import ProductDetail from './Components/ProductDetail';
import CartProducts from './Components/CartProducts';
import CategoryProduct from './Components/CategoryProduct';
import Shop from './Components/Shop';
import About from './Components/About';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import CheckOut from './Components/CheckOut';
import OrderDetail from './OrderRecord/OrderDetail';
import OrderComplete from './Components/OrderComplete';
import Orders from './Adminpanel/Orders';
import ScrollToTp from './ScrollToTp';
import Collections from './Components/Collections';
import ShopManager from './ShopManager/ShopManager';
import AllProducts from './ShopManager/AllProducts';
import AddProduct from './ShopManager/AddProducts';
import ShopManagerProfile from './OrderRecord/ShopManagerProfile';
import AdminLogin from './Components/AdminLogin';
import WhishList from './Components/WhishList';
import TopBar from './Components/TopBar';
import OrderTracking from './Components/OrderTracking';
function App() {
  return (
    <div>
      <Router>
        <MyProvider>
          <TopBar />
          <Navbar />
          <ScrollToTp />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/signUp' element={<SignUp />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/signIn' element={<SignIn />} />
            <Route path='/check-out' element={<CheckOut />} />
            <Route path='/order-complete' element={<OrderComplete />} />
            <Route path='/category-product/:category' element={<CategoryProduct />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='/product-detail/:title' element={<ProductDetail />} />
            <Route path='/cart-product' element={<CartProducts />} />
            <Route path='/categories' element={<Collections />} />
            <Route path='/admin-login' element={<AdminLogin />} />
            <Route path='/whish-list' element={<WhishList />} />
            <Route path='/order-tracking' element={<OrderTracking/>} />
            {/* admin panel */}
            <Route path='/admin-panel' element={<Adminpanel />}>
              <Route index element={<Navigate to="users" />} />
              <Route path='users' element={<Users />} />
              <Route path='orders' element={<Orders />} />
              <Route path='add-product' element={<AddProducts />} />
              <Route path='add-category' element={<AddCategory />} />
            </Route>
            {/* admin panel */}

            {/* user order pages */}
            <Route path='/order-detail' element={<OrderDetail />}>
              <Route index element={<Navigate to="orders" />} />
              <Route path='orders' element={<Orders />} />
              <Route path='profile' element={<ShopManagerProfile />} />
            </Route>
            {/* user order pages */}

            {/* shop manager pages */}
            <Route path='/shop-manager' element={<ShopManager />} >
              <Route index element={<Navigate to="add-product" />} />
              <Route path='all-products' element={<AllProducts />} />
              <Route path='add-product' element={<AddProduct />} />
            </Route>
            {/* shop manager pages */}
          </Routes>
        </MyProvider>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
