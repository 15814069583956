import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import MyContext from '../ContextApi/MyCOntext'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Form, Tab, Tabs } from "react-bootstrap"

export default function ProductDetail() {
    const { title } = useParams()
    const [imgUrl, setImgUrl] = useState()

    const [key, setKey] = useState('description');
    const { setCartQty, Products, reviews, allReviews } = useContext(MyContext)

    const [cartNumber, setCartNumber] = useState(1)
    const [cartMessage, setCartmessage] = useState("")
    const [detailProduct, setDetailProduct] = useState([])

    const [productReview, setProductReview] = useState({
        name: "",
        email: "",
        review: "",
        rating: ""
    })

    const formattedTitle = title.replace(/_/g, ' ')
    const titleProduct = async () => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getProduct/${formattedTitle}`, {
            method: "GET"
        })
        const data = await res.json()
        setDetailProduct(data)
        if (data.images && data.images.length > 0) {
            setImgUrl(data.images[0])
        }
    }
    useEffect(() => {
        titleProduct()
    }, [title])

    const reduceCartNumber = () => {
        if (cartNumber >= 2) {
            setCartNumber(cartNumber - 1)
        }
    }

    const addToCart = async () => {
        const existingCart = JSON.parse(sessionStorage.getItem("cart")) || []
        const existingProductIndex = existingCart.findIndex(item => item.product._id === detailProduct._id)
        if (existingProductIndex !== -1) {
            existingCart[existingProductIndex].quantity += cartNumber
        } else {
            existingCart.push({
                product: detailProduct,
                quantity: cartNumber
            })
        }
        setCartmessage("Added product in cart successfully")
        setTimeout(() => {
            setCartmessage("")
        }, 3000);
        sessionStorage.setItem("cart", JSON.stringify(existingCart))

        const storedCart = JSON.parse(sessionStorage.getItem("cart"));

        if (storedCart) {
            const subtotal = storedCart.reduce((acc, product) => acc + product.quantity, 0);
            setCartQty(subtotal);
        }
    }

    const addReview = async (e) => {
        e.preventDefault()
        const { name, email, review, rating } = productReview
        const productId = detailProduct._id

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/addReview", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, email, review, rating, productId })
        })
        if (res.ok) {
            allReviews()
            setProductReview({
                name: "",
                email: "",
                review: "",
                rating: ""
            })
        }
    }
    const onchange = (e) => {
        setProductReview({ ...productReview, [e.target.name]: e.target.value })
    }

    const renderStars = (count) => {
        return [...Array(count)].map((_, i) => (
            <i className='fas fa-star'></i>
        ))
    };

    const formatTime = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };
    return (
        <div className='pb-5'>
            <div className="container py-4">
                <div className="row justify-content-center">
                    <div className="col-md-5">

                        <img className='img-fluid zoom-effect productDetail-img' style={{ height: "400px", width: "100%" }} src={imgUrl} alt="" />
                        <div className="row mt-4">
                            {detailProduct.images && detailProduct.images.map((imagesUrl) => {
                                return <div className='col-md-2 col-2' key={imagesUrl.id}>
                                    <img className='img-fluid zoom-effect productDetail-img' src={imagesUrl} alt="" onClick={() => setImgUrl(imagesUrl)} />
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div id="cartConfirm" className='bg-danger text-white' style={{ display: "inline-block" }}>{cartMessage}</div>
                        <h2>{detailProduct.title}</h2>
                        <p>{detailProduct.description && detailProduct.description.length > 150 ? detailProduct.description.slice(0, 150) + "..." : detailProduct.description}</p>
                        <h4>Price: {detailProduct.price}</h4>
                        <button className='btn btn-outline-danger' onClick={reduceCartNumber}>-</button>
                        <button className='btn btn-outline-danger'>{cartNumber}</button>
                        <button className='btn btn-outline-danger' onClick={() => setCartNumber(cartNumber + 1)}>+</button>
                        <button className='mt-3 btn btn-outline-danger' style={{ display: "block" }} onClick={addToCart}>Add To Cart</button>
                        <p className='mt-2'><strong>Category:</strong> {detailProduct.categoryId && detailProduct.categoryId.category}</p>
                    </div>
                    <div className="col-md-12 mt-5">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="description" title="Description">
                                <p>{detailProduct.description}</p>
                            </Tab>
                            <Tab eventKey="review" title="Review">
                                <Form onSubmit={addReview}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type='text' name='name' value={productReview.name} onChange={onchange} />
                                    </Form.Group>
                                    <Form.Group className='mt-3'>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type='Email' name='email' value={productReview.email} onChange={onchange} />
                                    </Form.Group>
                                    <Form.Group className='mt-3'>
                                        <Form.Label>Your Rating</Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                {[1, 2, 3, 4, 5].map(num => (
                                                    <Form.Check
                                                        inline
                                                        label={renderStars(num)}
                                                        name="rating"
                                                        value={num}
                                                        type={type}
                                                        id={`inline-${type}-${num}`}
                                                        onChange={onchange}
                                                    />
                                                ))}
                                            </div>
                                        ))}
                                    </Form.Group>
                                    <Form.Group className='mt-3'>
                                        <Form.Label>Your Review</Form.Label>
                                        <Form.Control type='text' as="textarea" rows={5} name='review' value={productReview.review} onChange={onchange} />
                                    </Form.Group>
                                    <Button type='submit' className='mt-3'>Submit</Button>
                                </Form>

                                <div className="row mt-5">
                                    <div className="col-md-8 card" style={{ border: "none" }}>
                                        {reviews && reviews.filter(review => review.productId.title === detailProduct.title).map(review => (
                                            <div className='card'>
                                                <div className="card-body reviewBody">
                                                    <h4>{review.name}</h4>
                                                    <p className='mb-2' style={{ margin: "0" }}>{renderStars(Number(review.rating))}</p>
                                                    <p>{review.review}</p>
                                                    <p className='reviewTime'>{formatTime(review.createdAt)}</p>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>


                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <h1>Related Products</h1>
                    {Products && Products.filter(product => product.categoryId?.category === detailProduct.categoryId?.category && product.title !== detailProduct.title).map((product) => {
                        return <div key={product._id} className="col-md-3 latest-product">
                            <div className="card productCard">
                                <div className="product-logos">
                                    <h4 onClick={() => addToCart(product)} className="mt-4"><i className='fas  fa-cart-plus'></i></h4>
                                </div>
                                <Link to={`/product-detail/${product.title.replace(/ /g, '_')}`}>
                                    <img src={product.images[0]} className="img-fluid product-image" alt="" />
                                    <h5 className="text-center">{product.categoryId && product.categoryId.category}</h5>
                                    <h6 className="text-center">{product.title.length > 35 ? product.title.slice(0, 35) + "..." : product.title}</h6>
                                    <h6 className="text-center">Rs.{product.price}</h6>
                                </Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>


        </div >
    )
}
