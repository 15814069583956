import { useEffect, useState } from "react";
import MyContext from "./MyCOntext";

const MyProvider = ({ children }) => {
    const storedUser = JSON.parse(sessionStorage.getItem("signUp") || "{}")
    const [signUser, setSignUser] = useState(storedUser)
    const [Users, setUsers] = useState([])
    const [reviews, setReviews] = useState([])
    const [cartQty, setCartQty] = useState(0)
    const [whishListQty, setWhishListQty] = useState(0)
    const [orders, setOrders] = useState([])
    const [productViewId, setProductViewId] = useState("")
    const [uniqueCategory, setUniqueCategory] = useState(new Set())
    const [ProductTitle, setProductTitle] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [Products, setProducts] = useState([])
    const [ProductById, setProductById] = useState([])
    const [ProductByTitle, setProductByTitle] = useState([])
    const [Categories, setCategories] = useState([])
    const [orderId, setOrderId] = useState([])
    const [order, setOrder] = useState([])

    useEffect(() => {
        sessionStorage.setItem("signUp", JSON.stringify(signUser))
    }, [signUser])
    const allUsers = async () => {
        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/allUsers", {
            method: "GET"
        })
        const data = await res.json()
        setUsers(data)
    }
    const allProducts = async () => {
        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/allProducts", {
            method: "GET"
        })
        const data = await res.json()
        setProducts(data)
        if (data) {
            const newCategory = new Set(data.map(product => product.categoryId.category))
            setUniqueCategory(newCategory)
        }
    }

    const productsId = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getProdct/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setProductById(data)
    }
    const productsTitle = async (title) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getProduct/${title}`, {
            method: "GET"
        })
        const data = await res.json()
        setProductByTitle(data)
    }

    const allCategories = async () => {
        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/allCategories", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setCategories(data)
    }

    const allOrders = async () => {
        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/allOrders", {
            method: "GET"
        })
        const data = await res.json()
        setOrders(data)
    }

    const orderData = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/orderDetail/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setOrder(data)
    }

    const allReviews = async () => {
        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/getReview", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        console.log(data);

        setReviews(data)
    }

    useEffect(() => {
        allUsers()
        allProducts()
        allOrders()
        allReviews()
        productsId(productViewId)
        productsTitle(ProductTitle)
        allCategories()
        orderData(orderId)
    }, [productViewId, ProductTitle, orderId])


    return (
        <MyContext.Provider value={{ Products, Categories, allProducts, Users, allUsers, ProductById, setProductTitle, ProductByTitle, setOrderId, order, uniqueCategory, filteredProducts, setProductViewId, cartQty, setCartQty, orders, allOrders, allCategories, setFilteredProducts, setSignUser, signUser, whishListQty, setWhishListQty, reviews, allReviews }}>
            {children}
        </MyContext.Provider>
    )
}

export default MyProvider;