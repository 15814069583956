import React, { useContext, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import { Link } from 'react-router-dom'

export default function Shop() {
    const { uniqueCategory, Products, setCartQty, setWhishListQty } = useContext(MyContext)
    const cartNumber = 1
    const [category, setCategory] = useState("")
    const [searchProducts, setSearchProducts] = useState()
    const [productPrice, setProductPrice] = useState(null)
    const filterProducts = Products && Products.filter(product => {

        if (category && product.categoryId.category !== category) {
            return false;
        }

        if (productPrice !== null) {
            if (productPrice === 3000) {
                return product.price <= 3000
            }
            if (productPrice === 5000) {
                return product.price >= 3001 && product.price <= 5000
            }
            if (productPrice === 8000) {
                return product.price >= 5001 && product.price <= 8000
            }
            if (productPrice === 10000) {
                return product.price > 8000
            }
        }
        return true; // Include products that match the criteria
    }).sort((a, b) => {
        // Sort based on selected sorting option
        switch (searchProducts) {
            case "Newest Products":
                return new Date(b.createdAt) - new Date(a.createdAt);
            case "Low to high price":
                return parseFloat(a.price) - parseFloat(b.price);
            case "High to low price":
                return parseFloat(b.price) - parseFloat(a.price);
            default:
                return 0;
        }
    });

    const addToWhishList = (product) => {
        const WhishList = JSON.parse(localStorage.getItem("whishList")) || []
        WhishList.push(product)
        localStorage.setItem("whishList", JSON.stringify(WhishList))

        const existingWhishList = JSON.parse(localStorage.getItem("whishList"))
        setWhishListQty(existingWhishList.length);
    }

    const addToCart = (product) => {
        const existingCart = JSON.parse(sessionStorage.getItem("cart")) || []
        const existingProductIndex = existingCart.findIndex(item => item.product._id === product._id)
        if (existingProductIndex !== -1) {
            existingCart[existingProductIndex].quantity += cartNumber
        } else {
            existingCart.push({
                product: product,
                quantity: cartNumber
            })
        }
        sessionStorage.setItem("cart", JSON.stringify(existingCart))
        const storedCart = JSON.parse(sessionStorage.getItem("cart"))
        console.log(storedCart);
        if (storedCart) {
            const subCart = storedCart.reduce((acc, product) => acc + product.quantity, 0)
            setCartQty(subCart)
        }

    }
    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Shop Now</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="col-md-3 px-5">
                        <h2>Categories</h2>
                        {uniqueCategory && [...uniqueCategory].map((product) => {
                            return <button className='btn btn-outline-dark mt-3 w-100 text-start' onClick={() => setCategory(product)}><i className='fas fa-arrow-right me-2' style={{ fontSize: "13px" }}></i>{product}</button>
                        })}

                        <h2 className='mt-3'>Product Filters</h2>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setProductPrice(3000)} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Price Between 1000 To 3000
                            </label>
                        </div>
                        <div className="form-check mt-3">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setProductPrice(5000)} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Price Between 3000 To 5000
                            </label>
                        </div>
                        <div className="form-check mt-3">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setProductPrice(8000)} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Price Between 5000 To 8000
                            </label>
                        </div>
                        <div className="form-check mt-3">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setProductPrice(10000)} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Price More Than 8000
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row justify-content-end">
                            <div className="col-md-4">
                                <select name="search" id="search" value={searchProducts} onChange={(e) => setSearchProducts(e.target.value)} className='form-control border-danger'>
                                    <option value="">Sorting Products</option>
                                    <option>Newest Products</option>
                                    {/* <option>Low To High Price</option>
                                    <option>High To Low Price</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="row g-4 mt-3">
                            {filterProducts && filterProducts.map((product) => {
                                return <div key={product._id} className="col-md-4">
                                    <div className="card productCard latest-product">
                                        <div className="product-logos">
                                            <h4 onClick={() => addToWhishList(product)} ><i className='fas fa-heart'></i></h4>
                                            <h4 onClick={() => addToCart(product)} className='mt-4'><i className='fas  fa-cart-plus'></i></h4>
                                        </div>
                                        <Link to={`/product-detail/${product.title.replace(/ /g, '_')}`}>
                                            <img src={product.images[0]} className="img-fluid product-image" alt="" />
                                            <h5 className="text-center">{product.categoryId && product.categoryId.category}</h5>
                                            <h6 className="text-center">{product.title.length > 35 ? product.title.slice(0, 35) + "..." : product.title}</h6>
                                            <h6 className="text-center">Rs.{product.price}</h6>
                                        </Link>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
