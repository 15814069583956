import React from 'react'
import aboutImg from "../img/eCommerce-Marketplace.png"

import { Link } from 'react-router-dom';
import callToImg from "../img/call-to-action.png"
import Testimonial from './Testimonial';

export default function About() {

    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>About Us</h1>
                </div>
            </div>
            {/* page header end */}

            <div className="container">
                <div className="row">
                    <div className="col-md-3 mt-2">
                        <div className='about-card'>
                            <i className="fas fa-headset fa-3x"></i>
                            <h5>Customer Care</h5>
                        </div>
                    </div>
                    <div className="col-md-3 mt-2">
                        <div className='about-card'>
                            <i className="fas fa-exchange-alt fa-3x"></i>
                            <h5>Exchange Policy</h5>
                        </div>
                    </div>
                    <div className="col-md-3 mt-2">
                        <div className='about-card'>
                            <i className="fas fa-shipping-fast fa-3x"></i>
                            <h5>Free Shiping</h5>
                        </div>
                    </div>
                    <div className="col-md-3 mt-2">
                        <div className='about-card'>
                            <i className="fas fa-percent fa-3x"></i>
                            <h5>Discount</h5>
                        </div>
                    </div>
                </div>
            </div>

            {/* story start */}
            <div className="container py-5">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-6">
                        <h2>Story Behind</h2>
                        <p>At Galaxify, we curate a diverse array of products ranging from exquisite artificial jewelry to cutting-edge electronics and fashionable clothing. Our mission is to provide a seamless shopping experience where style meets innovation. With a commitment to quality and trendsetting design, we strive to offer curated collections that cater to every aspect of modern living. Whether you're looking to accessorize with elegance, upgrade your tech essentials, or refresh your wardrobe, Galaxify is your ultimate destination for curated products that elevate your lifestyle.</p>
                        <h5>Unique Team</h5>
                        <p>Our team at Galaxify blends diverse expertise, merging creativity with technical prowess to innovate across our products. Each member embodies a passion for excellence, driving us to exceed in design and customer experience. Collaboration is key; we leverage our strengths to deliver seamless shopping experiences. With a customer-centric approach, we craft products that resonate deeply.</p>
                        <h5>Core Values</h5>
                        <p>At Galaxify, our values define us. Innovation drives us forward, leading market trends and exceeding expectations. Quality is our hallmark, ensuring integrity in every product. Integrity is key; we build trust with transparency in all relationships. Sustainability guides us, promoting responsible practices for a better future.</p>
                    </div>
                    <div className="col-md-5">
                        <img src={aboutImg} className='img-fluid' alt="" />
                    </div>
                </div>
            </div>

            {/* call to action start */}
            <div className="cal-bg">
                <div className="container-fluid call-to-action my-4">
                    <div className="container">
                        <div className="row align-items-center justify-content-center pt-4 pb-2">
                            <div className="col-md-5">
                                <h3 className="mb-2"> Our Company & Values</h3>
                                <p> With a focus on timeless pieces that blend comfort and style, Al Mahdia has become a go-to choice for modern individuals seeking sophisticated and versatile wardrobe staples</p>
                                <Link to="/contact-us"> <button className='btn btn-danger'>Get In Touch</button></Link>
                            </div>
                            <div className="col-md-4">
                                <img src={callToImg} className="img-fluid" alt="" />
                            </div>
                        </div >
                    </div>
                </div >
            </div>
            {/* call to action end */}
            <Testimonial />
            {/* story end */}

        </div>
    )
}
