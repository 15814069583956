import React from 'react'
import testimonialImg from "../img/testimonial-img.webp"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Testimonial() {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        pauseOnHover: false,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
    };
    return (
        <div style={{ overflow: "hidden" }}>
            <div className="container py-4">
                <div className="row justify-content-between align-items-center">
                    <h2 className='text-center py-2'>Words Of Recognition From Our Customers</h2>
                    <div className="col-md-7">
                        <p>Explore firsthand experiences from our valued customers at Galaxify. Discover why customers rave about their Galaxify purchases and how our products have made a positive impact on their lives. Read heartfelt testimonials that highlight our commitment to quality, style, and customer satisfaction. Find out why Galaxify is the preferred choice for discerning shoppers seeking innovative products and exceptional service.</p>
                    </div>
                    <div className="col-md-5">
                        <div className='cal-bg'>
                            <div className="slider-container">
                                <Slider {...settings}>
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <img src={testimonialImg} style={{ height: "120px", width: "120px" }} alt="" />
                                        </div>
                                        <h2 className='text-center'>Qurat Ul Ain</h2>
                                        <p className='text-center'>I am absolutely in love with Galaxify! The exquisite fabrics, intricate embroidery, and impeccable fit make me feel like a queen. Thank you for creating such a remarkable Pakistani brand !</p>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <img src={testimonialImg} style={{ height: "120px", width: "120px" }} alt="" />
                                        </div>
                                        <h2 className='text-center'>Haram Ashraf</h2>
                                        <p className='text-center'>Galaxify is my ultimate destination for women’s fashion. Their collection is always in vogue, and the staff is friendly and knowledgeable. I’ve been a loyal customer for years and can’t recommend Galaxify enough.</p>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <img src={testimonialImg} style={{ height: "120px", width: "120px" }} alt="" />
                                        </div>
                                        <h2 className='text-center'>Maham Naeem</h2>
                                        <p className='text-center'>Galaxify is a women’s fashion haven. Their clothing is not only stylish but also built to last. I trust Galaxify to keep my wardrobe up to date, and I constantly receive compliments on their outfits.</p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
