import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom"
import MyContext from '../ContextApi/MyCOntext';
export default function CartProducts() {
    const [cartProducts, setCartProducts] = useState([]);
    const [addSubTotal, setAddSubTotal] = useState(0);
    const { setCartQty } = useContext(MyContext)
    useEffect(() => {
        const storedCart = JSON.parse(sessionStorage.getItem("cart"));
        if (storedCart) {
            setCartProducts(storedCart);
            updateCartSubtotal(storedCart);
        }

    }, []);

    const updateCartSubtotal = (products) => {
        const subtotal = products.reduce((acc, product) => acc + (product.product.price * product.quantity), 0);
        setAddSubTotal(subtotal);
    };

    const updateCartInStorage = (updatedProducts) => {
        sessionStorage.setItem("cart", JSON.stringify(updatedProducts));
    };

    const reduceQuantity = (id, currentQuantity) => {
        const updatedCart = cartProducts.map(product => {
            if (product.product._id === id && product.quantity > 0) {
                return {
                    ...product,
                    quantity: currentQuantity - 1
                };
            }
            return product;
        });
        setCartProducts(updatedCart);
        updateCartInStorage(updatedCart);
        updateCartSubtotal(updatedCart);
    };

    const increaseQuantity = (id, currentQuantity) => {
        const updatedCart = cartProducts.map(product => {
            if (product.product._id === id) {
                return {
                    ...product,
                    quantity: currentQuantity + 1
                };
            }
            return product;
        });
        setCartProducts(updatedCart);
        updateCartInStorage(updatedCart);
        updateCartSubtotal(updatedCart);
    };

    const delProduct = (id) => {
        const updatedCart = cartProducts.filter(product => product.product._id !== id);
        const checkQuantity = updatedCart.reduce((acc, curr) => acc + curr.quantity, 0)
        setCartQty(checkQuantity);
        setCartProducts(updatedCart);
        updateCartInStorage(updatedCart);
        updateCartSubtotal(updatedCart);
    };

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Image</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">SubTotal</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartProducts.map((product) => (
                                        <tr key={product.product._id}>
                                            <td><img src={product.product.images[0]} className='img-fluid' style={{ height: "70px", width: "70px" }} alt="" /></td>
                                            <td>{product.product.title}</td>
                                            <td>Rs.{product.product.price}</td>
                                            <td>
                                                <button className='btn btn-outline-dark' onClick={() => reduceQuantity(product.product._id, product.quantity)}>-</button>
                                                <button className='btn btn-outline-dark'>{product.quantity}</button>
                                                <button className='btn btn-outline-dark' onClick={() => increaseQuantity(product.product._id, product.quantity)}>+</button>
                                            </td>
                                            <td>Rs.{product.product.price * product.quantity}</td>
                                            <td onClick={() => delProduct(product.product._id)}> <i className="fa fa-trash"></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <section className='py-4'>
                <div className="container">
                    <div className="row d-flex justify-content-between align-items-end">
                        <div className="col-md-4">
                            <Link to="/shop"> <button className='btn btn-danger'><i className='fas fa-arrow-left'></i> Continue Shopping</button> </Link>
                        </div>
                        <div className="col-md-4 ">
                            <div className="row justify-content-between">
                                <div className="col-md-5 col-3">
                                    <h4>SubTotal</h4>
                                    <h4 className='mt-4'>Total</h4>
                                </div>
                                <div className="col-md-6 col-8">
                                    <h4 className='text-danger'>{addSubTotal}</h4>
                                    <h4 className='mt-4 text-danger'>{addSubTotal}</h4>
                                </div>
                            </div>

                            <Link to="/check-out"> <button className='btn btn-danger mt-4 w-100'>CHECKOUT</button> </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
