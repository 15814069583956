import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../img/galaxify-logo1.png"
import MyContext from "../ContextApi/MyCOntext";

export default function UserNavbar() {
    const { setCartQty, cartQty, signUser } = useContext(MyContext)
    const location = useLocation()
    if (location.pathname === "/admin-panel/users" || location.pathname === "/admin-panel/add-category" || location.pathname === "/admin-panel/add-product" || location.pathname === "/admin-panel/orders") {
        return;
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light home-bar">
                <div className="container-fluid px-4">
                    <Link className="navbar-brand" to="/"><img src={logoImg} alt="" style={{ width: "200px" }} /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ backgroundColor: "white" }}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link text-white" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/categories">Collections</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" aria-current="page" to="/shop">Shop</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/contact-us">Contact us</Link>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <Link className="nav-link text-white" to="/cart-product" onClick={() => setCartQty(0)}>
                                <i className="fas fa-shopping-cart"></i>
                                {cartQty > 0 && (
                                    <span className="badge bg-primary rounded-pill">{cartQty}</span>
                                )}
                            </Link>
                            <li className="nav-link text-white" data-bs-toggle="collapse"
                                href="#collapseExample"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"><i className="fas fa-user"></i></li>
                        </div>
                    </div>

                </div>
            </nav>

            <div className="container-fluid">
                <div className="row d-flex justify-content-end">
                    <div className="col-md-2">
                        <div className="collapse profile-set" id="collapseExample">
                            <div className="card card-body">
                                <h6 className="text-center">{signUser.name}</h6>
                                <div className="d-flex justify-content-center">
                                    <h3 className="text-center">{signUser.name.charAt(0)}</h3>
                                </div>
                                <p className="text-center">{signUser.number}</p>
                                <h5 className="text-cenetr">{signUser.email}</h5>
                                <h6 className="text-center"> <Link to="/signin">Log Out</Link></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}