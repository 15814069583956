import React, { useContext, useState } from "react"
import Swal from "sweetalert2"
import MyContext from "../ContextApi/MyCOntext"
export default function AddProducts() {
    const { Products, Categories, ProductById, setProductViewId, allProducts } = useContext(MyContext)
    const user = JSON.parse(sessionStorage.getItem("signUp"))
    const [editProducts, setEditProducts] = useState("")
    const [product, setProduct] = useState({
        title: "",
        price: "",
        description: "",
        images: [],
        categoryId: ""
    })
    const [imageIndex, setImageIndex] = useState("")
    // add product
    const addproduct = async (e) => {
        e.preventDefault()
        const { title, price, description, images, categoryId } = product
        const titleError = document.getElementById("titleError")
        const categoryIdError = document.getElementById("categoryIdError")
        const priceError = document.getElementById("priceError")
        const descriptionError = document.getElementById("descriptionError")
        const imageError = document.getElementById("imageError")
        let emptyFieldError = false
        if (!categoryId) {
            categoryIdError.innerText = "please enter category"
            emptyFieldError = true
        } else {
            categoryIdError.innerText = ""
        }
        if (!title) {
            titleError.innerText = "please enter title"
            emptyFieldError = true
        } else {
            titleError.innerText = ""
        }
        if (!description) {
            descriptionError.innerText = "please add description"
            emptyFieldError = true
        } else {
            descriptionError.innerText = ""
        }
        if (!images || images.length === 0) {
            imageError.innerText = "please select image"
            emptyFieldError = true
        } else if (images.length > 5) {
            imageError.innerText = "Only allowed upto 5 images"
            emptyFieldError = true
        } else {
            imageError.innerText = ""
        }
        if (!price) {
            priceError.innerText = "please add product price"
            emptyFieldError = true
        } else {
            priceError.innerText = ""
        }
        if (emptyFieldError) {
            return;
        }
        const formData = new FormData();
        formData.append("title", title);
        formData.append("price", price);
        formData.append("description", description);
        formData.append("categoryId", categoryId);
        formData.append("userId", user._id);

        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/addProducts", {
            method: "POST",
            body: formData
        })
        const data = await res.json()
        data.message === "Title already used" ? titleError.innerText = "Title already used" : titleError.innerText = ""
        if (!res.ok) {
            console.log(`Error: ${res.status} - ${res.statusText}`);
        } else {
            setProduct({
                title: "",
                price: "",
                description: "",
                images: [],
                categoryId: ""
            })
            Swal.fire({
                position: "center",
                icon: "success",
                title: "product has uploaded",
                showConfirmButton: false,
                timer: 1500
            });
            allProducts()
        }

    }

    const onchange = (e) => {
        if (e.target.files) {
            setProduct({ ...product, images: e.target.files });
        } else {
            setProduct({ ...product, [e.target.name]: e.target.value });
        }
    };

    const editProduct = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getProdct/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setEditProducts(data)
    }

    const updateProducts = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });
        if (isConfirmed) {
            const { title, price, description, categoryId, images } = editProducts

            const formData = new FormData();
            formData.append("title", title);
            formData.append("price", price);
            formData.append("description", description);
            console.log(imageIndex);
            console.log(images);

            for (let i = 0; i < images.length; i++) {
                formData.append("images", images[i])
            }

            if (Array.isArray(imageIndex)) {
                formData.append("imageIndex", JSON.stringify(imageIndex));
            }

            if (categoryId) {
                if (typeof categoryId === "object") {
                    formData.append("categoryId", categoryId._id);
                } else {
                    formData.append("categoryId", categoryId);
                }
            }

            await fetch(`https://galaxify-backend.vercel.app/api/auth/updateProdct/${editProducts._id}`, {
                method: "PUT",
                body: formData
            })
            allProducts()
            Swal.fire("Saved!", "", "success");
            setImageIndex("")
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }

    }

    const onchnge = (e) => {
        if (e.target.files) {
            setEditProducts({ ...editProducts, images: e.target.files })
        } else {
            setEditProducts({ ...editProducts, [e.target.name]: e.target.value })
        }
    }

    const delProductsId = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
            return result;
        });
        if (isConfirmed) {
            await fetch(`https://galaxify-backend.vercel.app/api/auth/delProduct/${id}`, {
                method: "DELETE"
            })
            allProducts()
        }
    }
    return (
        <div>
            <div className="d-flex justify-content-end p-3">
                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Add Product
                </button>
            </div>

            {/* products table */}
            <div className="col-md-11 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">category</th>
                            <th scope="col">Price</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Products && Products.map((product) => {
                            return <tr key={product._id}>
                                <td>{product.title}</td>
                                <td>{product.categoryId && product.categoryId.category}</td>
                                <td>{product.price}</td>
                                <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => setProductViewId(product._id)}></i>
                                    <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => editProduct(product._id)}></i>
                                    <i className="fa fa-trash" onClick={() => delProductsId(product._id)}></i>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {/* prodcut modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Product Modal</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addproduct} encType="multipart/form-data">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div id="error" className="text-danger text-center"></div>
                                        <input className="form-control" type="text" placeholder="product Title" name="title" value={product.title} onChange={onchange} />
                                        <div id="titleError" className="text-danger"></div>
                                        <input className="form-control mt-3" type="number" placeholder="product price" name="price" value={product.price} onChange={onchange} />
                                        <div id="priceError" className="text-danger"></div>

                                        <select name="categoryId" value={product.categoryId} onChange={onchange} id="category" className="form-control mt-3">
                                            <option value="">Select Category</option>
                                            {Categories && Categories.map((product) => {
                                                return <option key={product._id} value={product._id}>{product.category}</option>
                                            })}
                                        </select>
                                        <div id="categoryIdError" className="text-danger"></div>
                                        <input className="form-control mt-3" type="file" placeholder="Image" name="images" onChange={onchange} multiple />
                                        <div id="imageError" className="text-danger"></div>

                                        <textarea className="form-control mt-3" cols="30" rows="10" placeholder="product Description" name="description" value={product.description} onChange={onchange}></textarea>
                                        <div id="descriptionError" className="text-danger"></div>
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <button type="submit" className="btn btn-danger mt-3 text-center px-4 py-2">Upload Product</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* view modal */}
            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Product View Modal</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form encType="multipart/form-data">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <input className="form-control" value={ProductById.title} />
                                        <input className="form-control mt-3" value={ProductById.price} />
                                        <input className="form-control mt-3" value={ProductById.categoryId && ProductById.categoryId.category} />
                                        <input className="form-control mt-3" value={ProductById.image} />
                                        <textarea className="form-control mt-3" cols="30" rows="10" value={ProductById.description}></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* edit modal */}
            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Product Edit Modal</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <input className="form-control" type="text" placeholder="product Title" name="title" value={editProducts.title} onChange={onchnge} />
                                        <input className="form-control mt-3" type="text" placeholder="product price" name="price" value={editProducts.price} onChange={onchnge} />

                                        <select name="categoryId" value={editProducts.categoryId && editProducts.categoryId._id} onChange={onchnge} id="category" className="form-control mt-3">
                                            {Categories && Categories.map((product) => {
                                                return <option key={product._id} value={product._id} >{product.category}</option>
                                            })}
                                        </select>
                                        <input className="form-control mt-3" type="file" placeholder="Image" name="images" onChange={onchnge} multiple />
                                        <p className="mt-3" style={{ fontSize: "14px" }}>If you want to update any image in the gallery, give index number of the image in the following field. The index numbers start from 0 and goes upto 4 (as you can only add 5 images.) For example, if you want to update 3rd image in gallery, your index number will be 2. If you're adding images for the first time, then you don't need to give index numbers.</p>
                                        <input
                                            className="form-control mt-3"
                                            type="text"
                                            placeholder="Image Index to Replace (starting from 0)"
                                            name="imageIndex"
                                            value={imageIndex}
                                            onChange={(e) => setImageIndex(e.target.value.split(",").map(Number))}
                                        />
                                        <textarea className="form-control mt-3" cols="30" rows="10" placeholder="product Description" name="description" value={editProducts.description} onChange={onchnge}></textarea>
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <button className="btn btn-danger mt-3 text-center px-4 py-2" onClick={updateProducts}>Update Product</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}