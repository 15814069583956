import React, { useContext, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import Swal from 'sweetalert2'

export default function Users() {
    const { Users, allUsers } = useContext(MyContext)
    const [userView, setUserView] = useState([])
    const [editUser, setEditUser] = useState([])
    const { setSignUser } = useContext(MyContext)

    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        number: "",
        role: "customer"
    })

    const signUpFunc = async (e) => {
        e.preventDefault()
        const nameError = document.getElementById("nameError")
        const roleError = document.getElementById("roleError")
        const emailError = document.getElementById("emailError")
        const numberError = document.getElementById("numberError")
        const passwordError = document.getElementById("passwordError")
        const confPasswordError = document.getElementById("confPasswordError")
        const { name, email, password, confirmPassword, number, role } = user
        let emptyFieldError = false
        if (!name) {
            nameError.innerText = "Please enter name"
            emptyFieldError = true
        } else {
            nameError.innerText = ""
        }
        if (!role) {
            roleError.innerText = "Please select role"
            emptyFieldError = true
        } else {
            roleError.innerText = ""
        }

        if (!email) {
            emailError.innerText = "Please enter email"
            emptyFieldError = true
        } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            emailError.innerText = "Please enter a valid email address";
            emptyFieldError = true;
        } else {
            emailError.innerText = ""
        }
        if (!number) {
            numberError.innerText = "Please enter number"
            emptyFieldError = true
        } else if (!number.match(/^\d+$/)) {
            numberError.innerText = "Please enter only digits";
            emptyFieldError = true;
        } else {
            numberError.innerText = ""
        }
        if (!password) {
            passwordError.innerText = "Please enter password"
            emptyFieldError = true
        } else {
            passwordError.innerText = ""
        }
        if (!confirmPassword) {
            confPasswordError.innerText = "Please confirm password"
            emptyFieldError = true
        } else {
            confPasswordError.innerText = ""
        }
        if (emptyFieldError) {
            return;
        }

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/signUp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, email, number, password, confirmPassword, role })
        })
        const data = await res.json()
        if (data.message === "user with this email already exists") {
            emailError.innerText = "User with this email already exists"
        } else if (data.message === "This number already used") {
            numberError.innerText = "This number already used"
        } else if (data.message === "password does not match") {
            confPasswordError.innerText = "Password does not match"
        } else if (res.ok) {
            setSignUser(data)
            sessionStorage.setItem("signUp", JSON.stringify(data))
            Swal.fire({
                position: "center",
                icon: "success",
                title: "User created successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setUser({
                name: "",
                email: "",
                password: "",
                confirmPassword: "",
                number: "",
            })
            allUsers()
        }
    }

    const onchnge = (e) => {
        if (e.target.name === 'number') {
            if (!/^\d*$/.test(e.target.value)) {
                setUser((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value.replace(/\D/g, '') }));
                return;
            }
        }
        setUser({ ...user, [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value })
    }
    const userData = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/signUser/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setUserView(data)
    }

    const edtUser = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/signUser/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setEditUser(data)
    }

    const updateUser = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });
        if (isConfirmed) {
            const { name, email, number, role, password } = editUser
            await fetch(`https://galaxify-backend.vercel.app/api/auth/UpdateUser/${editUser._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ name, email, number, role, password })
            })
            allUsers()
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
    }

    const onchange = (e) => {
        setEditUser({ ...editUser, [e.target.name]: e.target.value })
    }

    const deleteUser = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
            return result;
        });
        if (isConfirmed) {
            await fetch(`https://galaxify-backend.vercel.app/api/auth/userDelete/${id}`, {
                method: "DELETE"
            })
            allUsers()
        }
    }
    return (
        <div>
            <div className="d-flex justify-content-end p-3">
                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModalAdd">
                    Add User
                </button>
            </div>
            <div className="col-md-11 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Users && Users.map((user) => {
                            return <tr>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => userData(user._id)}></i>
                                    <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => edtUser(user._id)}></i>
                                    <i className="fa fa-trash" onClick={() => deleteUser(user._id)}></i>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            {/*add modal */}
            <div className="modal fade" id="exampleModalAdd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={signUpFunc}>
                                {/* radio */}
                                <div className="d-flex">
                                    <div class="form-check mx-3">
                                        <input class="form-check-input" type="radio" name="role" id="customerRadio" value="customer" checked={user.role === "customer"} onChange={onchnge} />
                                        <label class="form-check-label" for="customerRadio">
                                            Customer
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="role" id="shopManagerRadio" value="shopManager" checked={user.role === "shopManager"} onChange={onchnge} />
                                        <label class="form-check-label" for="shopManagerRadio">
                                            Shop Manager
                                        </label>
                                    </div>
                                </div>
                                <div id="roleError" className='text-danger' ></div>
                                {/* radio */}
                                <input type="text" className='form-control' placeholder='Name' name='name' value={user.name} onChange={onchnge} />
                                <div id="nameError" className='text-danger' ></div>
                                <input type="email" className='form-control mt-3' placeholder='Email' name='email' value={user.email} onChange={onchnge} />
                                <div id="emailError" className='text-danger' ></div>
                                <input type="tel" className='form-control mt-3' placeholder='Number' name='number' value={user.number} onChange={onchnge} />
                                <div id="numberError" className='text-danger' ></div>
                                <input type="password" className='form-control mt-3' placeholder='Password' name='password' value={user.password} onChange={onchnge} />
                                <div id="passwordError" className='text-danger' ></div>
                                <input type="password" className='form-control mt-3' placeholder='Confirm Password' name='confirmPassword' value={user.confirmPassword} onChange={onchnge} />
                                <div id="confPasswordError" className='text-danger' ></div>
                                <div className='d-flex justify-content-center mt-3'>
                                    <button type='submit' className='btn btn-danger'>Sign Up</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/*view modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form >
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" name='name' value={userView.name} />
                                <label htmlFor="email" className='mt-3'>Email</label>
                                <input type="text" className="form-control" name='email' value={userView.email} />
                                <label htmlFor="number" className='mt-3'>Contact Number</label>
                                <input type="text" className="form-control" name='number' value={userView.number} />
                                <label htmlFor="password" className='mt-3'>Password</label>
                                <input type="text" className="form-control" name='password' value={userView.password} />
                                <label htmlFor="password" className='mt-3'>Role</label>
                                <input type="password" className="form-control" name='password' value={userView.role} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/*edit modal */}
            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form >
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" name='name' value={editUser.name} onChange={onchange} />
                                <label htmlFor="email" className='mt-3'>Email</label>
                                <input type="text" className="form-control" name='email' value={editUser.email} onChange={onchange} />
                                <label htmlFor="number" className='mt-3'>Contact Number</label>
                                <input type="text" className="form-control" name='number' value={editUser.number} onChange={onchange} />
                                <label htmlFor="password" className='mt-3'>Password</label>
                                <input type="text" className="form-control" name='password' value={editUser.password} onChange={onchange} />
                                <label htmlFor="role" className='mt-3'>Role</label>
                                <select className='form-control' name="role" id="role" value={editUser.role} onChange={onchange}>
                                    <option value="">Select role</option>
                                    <option value="admin">Admin</option>
                                    <option value="shopManager">Shop Manager</option>
                                    <option value="customer">Customer</option>
                                </select>
                                <button className="btn btn-danger mt-3" onClick={updateUser}>Update User</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
