import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import MyContext from '../ContextApi/MyCOntext'

export default function AddProduct() {
    const { allProducts, Categories } = useContext(MyContext)
    const user = JSON.parse(sessionStorage.getItem("signUp"))
    console.log(user._id)
    const [product, setProduct] = useState({
        title: "",
        price: "",
        description: "",
        images: [],
        categoryId: ""
    })
    // add product
    const addproduct = async (e) => {
        e.preventDefault()
        const { title, price, description, images, categoryId } = product
        const titleError = document.getElementById("titleError")
        const categoryIdError = document.getElementById("categoryIdError")
        const priceError = document.getElementById("priceError")
        const descriptionError = document.getElementById("descriptionError")
        const imageError = document.getElementById("imageError")
        let emptyFieldError = false
        if (!categoryId) {
            categoryIdError.innerText = "please enter category"
            emptyFieldError = true
        } else {
            categoryIdError.innerText = ""
        }
        if (!title) {
            titleError.innerText = "please enter title"
            emptyFieldError = true
        } else {
            titleError.innerText = ""
        }
        if (!description) {
            descriptionError.innerText = "please add description"
            emptyFieldError = true
        } else {
            descriptionError.innerText = ""
        }
        if (!images || images.length === 0) {
            imageError.innerText = "please select image"
            emptyFieldError = true
        } else if (images.length > 5) {
            imageError.innerText = "Only allowed upto 5 images"
            emptyFieldError = true
        } else {
            imageError.innerText = ""
        }
        if (!price) {
            priceError.innerText = "please add product price"
            emptyFieldError = true
        } else {
            priceError.innerText = ""
        }
        if (emptyFieldError) {
            return;
        }
        const formData = new FormData();
        formData.append("title", title);
        formData.append("price", price);
        formData.append("description", description);
        formData.append("categoryId", categoryId);
        formData.append("userId", user._id);

        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/addProducts", {
            method: "POST",
            body: formData
        })
        const data = await res.json()
        data.message === "Title already used" ? titleError.innerText = "Title already used" : titleError.innerText = ""
        if (!res.ok) {
            console.log(`Error: ${res.status} - ${res.statusText}`);
        } else {
            setProduct({
                title: "",
                price: "",
                description: "",
                images: [],
                categoryId: ""
            })
            Swal.fire({
                position: "center",
                icon: "success",
                title: "product has uploaded",
                showConfirmButton: false,
                timer: 1500
            });
            allProducts()
        }

    }

    const onchange = (e) => {
        if (e.target.files) {
            setProduct({ ...product, images: e.target.files });
        } else {
            setProduct({ ...product, [e.target.name]: e.target.value });
        }
    };

    return (
        <div>
            <form onSubmit={addproduct} encType="multipart/form-data">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div id="error" className="text-danger text-center"></div>
                        <input className="form-control" type="text" placeholder="product Title" name="title" value={product.title} onChange={onchange} />
                        <div id="titleError" className="text-danger"></div>
                        <input className="form-control mt-3" type="number" placeholder="product price" name="price" value={product.price} onChange={onchange} />
                        <div id="priceError" className="text-danger"></div>

                        <select name="categoryId" value={product.categoryId} onChange={onchange} id="category" className="form-control mt-3">
                            <option value="">Select Category</option>
                            {Categories && Categories.map((product) => {
                                return <option key={product._id} value={product._id}>{product.category}</option>
                            })}
                        </select>
                        <div id="categoryIdError" className="text-danger"></div>
                        <input className="form-control mt-3" type="file" placeholder="Image" name="images" onChange={onchange} multiple />
                        <div id="imageError" className="text-danger"></div>

                        <textarea className="form-control mt-3" cols="30" rows="10" placeholder="product Description" name="description" value={product.description} onChange={onchange}></textarea>
                        <div id="descriptionError" className="text-danger"></div>
                        <div className="col-md-12 d-flex justify-content-center">
                            <button type="submit" className="btn btn-danger mt-3 text-center px-4 py-2">Upload Product</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}
