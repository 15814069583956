import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export default function WhishList() {
    const [listedProducts, setListedProducts] = useState([])
    useEffect(() => {
        const whishListProducts = JSON.parse(localStorage.getItem("whishList"))
        setListedProducts(whishListProducts)
    }, [])
    const removeFromWhishList = (id) => {
        const whishListProducts = JSON.parse(localStorage.getItem("whishList"))
        const updateListedProducts = whishListProducts && whishListProducts.filter(product => product._id !== id)
        localStorage.setItem("whishList", JSON.stringify(updateListedProducts))
        setListedProducts(updateListedProducts)
    }
    return (
        <>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Whish List Products</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container py-4">
                <div className="row g-4 mt-3">
                    {listedProducts && listedProducts.length > 0 ? listedProducts.map((product) => {
                        return <div key={product._id} className="col-md-3">
                            <div className="card productCard">
                                <div className="product-logos" style={{ zIndex: "3", top: "0", padding: "8px" }}>
                                    <h5 onClick={() => removeFromWhishList(product._id)} ><i className='fas fa-times'></i></h5>
                                </div>
                                <Link to={`/product-detail/${product.title.replace(/ /g, '_')}`}>
                                    <div className="product-image-container">
                                        <img src={product.images[0]} className="img-fluid product-image" alt="" />
                                    </div>
                                    <h6 className='text-center mt-3'>{product.title}</h6>
                                    <h4 className='text-center'> Rs.{product.price}</h4>
                                </Link>
                            </div>
                        </div>
                    }) : (
                        <div>
                            <h1 className="text-center py-5">Not Have Any Whish List Poduct</h1>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
