import React from 'react'
import UserNavbar from '../Components/UserNavbar'
import Orders from './Orders'
import ShopManagerProfile from './ShopManagerProfile'

export default function OrderDetail() {
  return (
    <div>
      <UserNavbar />
      <div className="container">
        <div className="row py-3">
          <div className="col-md-12">
            <h2>Profile</h2>
          <ShopManagerProfile/>
            <h2 className='mt-4'>Orders</h2>
            <Orders/>
          </div>
        </div>
      </div>
      
    </div>
  )
}
