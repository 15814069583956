import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import MyContext from '../ContextApi/MyCOntext'

export default function AllProducts() {
  const { allProducts, ProductById, Products, setProductViewId, Categories } = useContext(MyContext)
  const user = JSON.parse(sessionStorage.getItem("signUp"))
  const [imageIndex, setImageIndex] = useState("")
  const [editProducts, setEditProducts] = useState("")
  const editProduct = async (id) => {
    const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getProdct/${id}`, {
      method: "GET"
    })
    const data = await res.json()
    setEditProducts(data)
  }

  const updateProducts = async (e) => {
    e.preventDefault()
    const { isConfirmed } = await Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    });
    if (isConfirmed) {
      const { title, price, description, categoryId, images } = editProducts

      const formData = new FormData();
      formData.append("title", title);
      formData.append("price", price);
      formData.append("description", description);
      console.log(imageIndex);
      console.log(images);

      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i])
      }

      if (Array.isArray(imageIndex)) {
        formData.append("imageIndex", JSON.stringify(imageIndex));
      }

      if (categoryId) {
        if (typeof categoryId === "object") {
          formData.append("categoryId", categoryId._id);
        } else {
          formData.append("categoryId", categoryId);
        }
      }

      await fetch(`https://galaxify-backend.vercel.app/api/auth/updateProdct/${editProducts._id}`, {
        method: "PUT",
        body: formData
      })
      allProducts()
      Swal.fire("Saved!", "", "success");
      setImageIndex("")
    } else {
      Swal.fire("Changes are not saved", "", "info");
    }

  }

  const onchnge = (e) => {
    if (e.target.files) {
      setEditProducts({ ...editProducts, images: e.target.files })
    } else {
      setEditProducts({ ...editProducts, [e.target.name]: e.target.value })
    }
  }

  const delProductsId = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      }
      return result;
    });
    if (isConfirmed) {
      await fetch(`https://al-mahdia-backend.vercel.app/api/auth/delProduct/${id}`, {
        method: "DELETE"
      })
      allProducts()
    }
  }
  return (
    <div>
      {/* products table */}
      <div className="col-md-11 table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">category</th>
              <th scope="col">Price</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Products && Products.filter(productUser => productUser.userId && productUser.userId.email === user.email).map((product) => {
              return <tr>
                <td>{product.title}</td>
                <td>{product.categoryId && product.categoryId.category}</td>
                <td>{product.price}</td>
                <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => setProductViewId(product._id)}></i>
                  <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => editProduct(product._id)}></i>
                  <i className="fa fa-trash" onClick={() => delProductsId(product._id)}></i>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      {/* view modal */}
      <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Product View Modal</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form encType="multipart/form-data">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <input className="form-control" value={ProductById.title} />
                    <input className="form-control mt-3" value={ProductById.price} />
                    <input className="form-control mt-3" value={ProductById.categoryId && ProductById.categoryId.category} />
                    <input className="form-control mt-3" value={ProductById.images[0]} />
                    <textarea className="form-control mt-3" cols="30" rows="10" value={ProductById.description}></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* edit modal */}
      <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Product Edit Modal</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <input className="form-control" type="text" placeholder="product Title" name="title" value={editProducts.title} onChange={onchnge} />
                    <input className="form-control mt-3" type="text" placeholder="product price" name="price" value={editProducts.price} onChange={onchnge} />

                    <select name="categoryId" value={editProducts.categoryId && editProducts.categoryId._id} onChange={onchnge} id="category" className="form-control mt-3">
                      {Categories && Categories.map((product) => {
                        return <option key={product._id} value={product._id} >{product.category}</option>
                      })}
                    </select>
                    <input className="form-control mt-3" type="file" placeholder="Image" name="images" onChange={onchnge} multiple />
                    <p className="mt-3" style={{ fontSize: "14px" }}>If you want to update any image in the gallery, give index number of the image in the following field. The index numbers start from 0 and goes upto 4 (as you can only add 5 images.) For example, if you want to update 3rd image in gallery, your index number will be 2. If you're adding images for the first time, then you don't need to give index numbers.</p>
                    <input
                      className="form-control mt-3"
                      type="text"
                      placeholder="Image Index to Replace (starting from 0)"
                      name="imageIndex"
                      value={imageIndex}
                      onChange={(e) => setImageIndex(e.target.value.split(",").map(Number))}
                    />
                    <textarea className="form-control mt-3" cols="30" rows="10" placeholder="product Description" name="description" value={editProducts.description} onChange={onchnge}></textarea>
                    <div className="col-md-12 d-flex justify-content-center">
                      <button className="btn btn-danger mt-3 text-center px-4 py-2" onClick={updateProducts}>Update Product</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
