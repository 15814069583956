import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MyContext from '../ContextApi/MyCOntext'

export default function SignUp() {
    const { setSignUser } = useContext(MyContext)
    const navigate = useNavigate()
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        number: "",
        role: "customer"
    })

    const signUpFunc = async (e) => {
        e.preventDefault()
        const nameError = document.getElementById("nameError")
        const emailError = document.getElementById("emailError")
        const numberError = document.getElementById("numberError")
        const passwordError = document.getElementById("passwordError")
        const confPasswordError = document.getElementById("confPasswordError")
        const { name, email, password, confirmPassword, number, role } = user
        let emptyFieldError = false
        if (!name) {
            nameError.innerText = "Please enter name"
            emptyFieldError = true
        } else {
            nameError.innerText = ""
        }

        if (!email) {
            emailError.innerText = "Please enter email"
            emptyFieldError = true
        } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            emailError.innerText = "Please enter a valid email address";
            emptyFieldError = true;
        } else {
            emailError.innerText = ""
        }
        if (!number) {
            numberError.innerText = "Please enter number"
            emptyFieldError = true
        } else if (!number.match(/^\d+$/)) {
            numberError.innerText = "Please enter only digits";
            emptyFieldError = true;
        } else {
            numberError.innerText = ""
        }
        if (!password) {
            passwordError.innerText = "Please enter password"
            emptyFieldError = true
        } else {
            passwordError.innerText = ""
        }
        if (!confirmPassword) {
            confPasswordError.innerText = "Please confirm password"
            emptyFieldError = true
        } else {
            confPasswordError.innerText = ""
        }
        if (emptyFieldError) {
            return;
        }

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/signUp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, email, number, password, confirmPassword, role })
        })
        const data = await res.json()

        if (data.message === "user with this email already exists") {
            emailError.innerText = "User with this email already exists"
        } else if (data.message === "This number already used") {
            numberError.innerText = "This number already used"
        } else if (data.message === "password does not match") {
            confPasswordError.innerText = "Password does not match"
        } else if (res.ok) {
            setSignUser(data)
            sessionStorage.setItem("signUp", JSON.stringify(data))
            navigate("/signIn")
        }
    }

    const onchange = (e) => {
        if (e.target.name === 'number') {
            if (!/^\d*$/.test(e.target.value)) {
                setUser((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value.replace(/\D/g, '') }));
                return;
            }
        }
        setUser({ ...user, [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value })
    }
    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Sign Up</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container py-3">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <form onSubmit={signUpFunc}>
                            <input type="text" className='form-control' placeholder='Name' name='name' value={user.name} onChange={onchange} />
                            <div id="nameError" className='text-danger' ></div>
                            <input type="email" className='form-control mt-3' placeholder='Email' name='email' value={user.email} onChange={onchange} />
                            <div id="emailError" className='text-danger' ></div>
                            <input type="tel" className='form-control mt-3' placeholder='Number' name='number' value={user.number} onChange={onchange} />
                            <div id="numberError" className='text-danger' ></div>
                            <input type="password" className='form-control mt-3' placeholder='Password' name='password' value={user.password} onChange={onchange} />
                            <div id="passwordError" className='text-danger' ></div>
                            <input type="password" className='form-control mt-3' placeholder='Confirm Password' name='confirmPassword' value={user.confirmPassword} onChange={onchange} />
                            <div id="confPasswordError" className='text-danger' ></div>
                            <div className='d-flex justify-content-center mt-3'>
                                <button type='submit' className='btn btn-danger'>Sign Up</button>
                            </div>
                        </form>
                        <h6 className='mt-3 text-center'>Already have account? <Link to="/signIn" style={{ textDecoration: "underline" }}> Sign In</Link></h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
