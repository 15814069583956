import React, { useContext, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import Swal from "sweetalert2"

export default function Orders() {
    const { orders, allOrders } = useContext(MyContext)
    console.log(orders);

    const [order, setOrder] = useState([])
    const [ordrEdit, setOrdrEdit] = useState("")
    const orderDetail = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/orders/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setOrder(data)
    }

    const orderEdit = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/editOrder/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        setOrdrEdit(data)
    }
    const UpdateStatus = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });
        if (isConfirmed) {
            const { orderStatus } = ordrEdit
            await fetch(`https://galaxify-backend.vercel.app/api/auth/updateOrder/${ordrEdit._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ orderStatus })
            })

            allOrders()
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
    }

    const onchnge = (e) => {
        setOrdrEdit({ ...ordrEdit, [e.target.name]: e.target.value })
    }

    function formatDate(dateString) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }
    return (
        <div>
            <div className='table-responsive'>
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Order Amount</th>
                            <th scope="col">Order Number</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.map((order, index) => {
                            return <tr key={order.id}>
                                <td>{order.name}</td>
                                <td>{order.email}</td>
                                <td>{order.orderAmount}</td>
                                <td>{order.orderNumber}</td>
                                <td>{order.orderStatus}</td>
                                <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => orderDetail(order._id)}></i>
                                    <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => orderEdit(order._id)}></i>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {/* prodcut modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Product Modal</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {order && order.length > 0 && (
                                <div>
                                    <h6><strong>Name:</strong> {order[0].orderId.name}</h6>
                                    <h6><strong>Address:</strong> {order[0].orderId.address}</h6>
                                    <h6><strong>Order Date:</strong> {formatDate(order[0].orderId.date)}</h6>
                                    <h6><strong>Order Number:</strong> {order[0].orderId.orderNumber}</h6>
                                    <h6><strong>Total Amount:</strong> {order[0].orderId.orderAmount}</h6>
                                    <h6><strong>Order Status:</strong> {order[0].orderId.orderStatus}</h6>
                                </div>
                            )}

                            <table className="table table-responsive mt-5">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">SubTotal</th>
                                        <th scope="col">Added Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order && order.map((order, index) => {
                                        return <tr >
                                            <td>{order.productId.title}</td>
                                            <td>{order.productId.price}</td>
                                            <td>{order.quantity}</td>
                                            <td>{order.subTotal}</td>
                                            <td>{order.orderId.addInformation}</td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* prodcut modal */}
            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Order Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="status">Order Status</label>
                            <form>
                                <select name="orderStatus" id="status" value={ordrEdit.orderStatus} className='form-control' onChange={onchnge}>
                                    <option value="Pending">Pending</option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Cancel">Cancel</option>
                                </select>
                                <button className='btn btn-primary mt-3' onClick={UpdateStatus}>Update Status</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
