import React from 'react'
import { Link, useLocation } from 'react-router-dom'


export default function Footer() {
    const location = useLocation()
    const handleClick = (pathname) => {
        if (location.pathname === pathname) {
            window.scrollTo(0, 0)
        }
    }

    if (location.pathname === "/admin-panel/users" || location.pathname === "/admin-panel/add-category" || location.pathname === "/admin-panel/add-product" || location.pathname === "/admin-panel/orders" || location.pathname === "/shop-manager/all-products" || location.pathname === "/shop-manager/add-product") {
        return;
    }
    return (
        <div className='footer'>
            <footer id="footer">
                <div className="footer-top pb-4">
                    <div className="container">
                        <div className="row pt-5 justify-content-between">

                            <div className="col-lg-3 col-md-6 footer-contact text-start">
                                <h2>Galaxify</h2>
                                <p>
                                    Our passion for trendsetting designs and commitment to quality has crafted a unique space for style enthusiasts.
                                </p>
                                <h2 >Follow Us</h2>
                                <div className="social-links d-flex mt-2">
                                    <div target='blank' className="me-3"><i className="fab fa-facebook"></i></div>
                                    <div target='blank' className="me-3"><i className="fab fa-twitter"></i></div>
                                    <div target='blank' className="me-3"><i className="fab fa-instagram"></i></div>
                                    <div target='blank' className="me-3"><i className="fab fa-linkedin"></i></div>
                                    <div target='blank'><i className="fab fa-youtube"></i></div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links text-start">
                                <h2>Quick Links</h2>
                                <div style={{ lineHeight: "2.5rem", listStyle: "none" }}>
                                    <div onClick={() => handleClick('/')}><i className="fas fa-arrow-right me-2"></i>
                                        <Link to="/">Home</Link></div>
                                    <div onClick={() => handleClick('/about')}><i className="fas fa-arrow-right me-2"></i> <Link to="/about">About Us</Link></div>
                                    <div onClick={() => handleClick('/shop')}><i className="fas fa-arrow-right me-2"></i> <Link to="/shop">Shop</Link></div>
                                    <div onClick={() => handleClick('/contact-us')}><i className="fas fa-arrow-right me-2"></i> <Link to="/contact-us">Contact</Link></div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 social-links">
                                <h2>Contact Us</h2>
                                <p>
                                    <strong>Address:</strong> Mahir Industries,Main GondlanWala Road, Gujranwala, Punjab, Pakistan.<br />
                                    <strong>Phone:</strong> +92-55-4236700<br />
                                    <strong>Email:</strong> info@galaxify.com<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container-fluid home-bar pt-3 pb-2">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="copyright footer-bottom text-white" style={{ display: "inline-block" }}>
                                        &copy; Copyright Galaxify. All Rights Reserved. Powered By <strong><a href='https://technicmentors.com/' target='blank' style={{ color: "initial", textDecoration: "underline" }}>Technic Mentors</a></strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
