import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import AdminNavbar from '../Components/AdminNavbar'

export default function ShopManager() {
    return (
        <div>
            <AdminNavbar/>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Shop Manager</h1>
                </div>
            </div>
            {/* page header end */}

            <div className="container-fluid">
                <div className="row py-3">
                    <div className="col-md-2">
                        <div>
                            <Link to="add-product"> <button className='btn btn-outline-danger'>Add Products</button></Link>
                        </div>
                        <div>
                            <Link to="all-products">  <button className='btn btn-outline-danger mt-3'>Your Products</button></Link>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}
