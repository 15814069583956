import React, { useContext, useEffect, useState } from "react"
import MyContext from "../ContextApi/MyCOntext"
import { Link } from "react-router-dom"
import headImg from "../img/head-1.png"
import callToImg from "../img/call-to-action.png"
import Testimonial from "./Testimonial"
import aboutImg from "../img/eCommerce-Marketplace.png"
export default function Home() {
    const { Products, setProductTitle, setCartQty, setWhishListQty } = useContext(MyContext)
    const [uniqueProducts, setUniqueProducts] = useState([])
    const cartNumber = 1
    useEffect(() => {
        const categories = new Set()
        const uniqueCategories = []
        const extractUnqiueProducts = (Products) => {
            Products.forEach(product => {
                if (product.categoryId && !categories.has(product.categoryId.category)) {
                    categories.add(product.categoryId.category);
                    uniqueCategories.push(product);
                }
            });
            return uniqueCategories;
        };

        setUniqueProducts(extractUnqiueProducts(Products))
    }, [Products])

    const addToWhishList = (product) => {
        const WhishList = JSON.parse(localStorage.getItem("whishList")) || []
        WhishList.push(product)
        localStorage.setItem("whishList", JSON.stringify(WhishList))

        const existingWhishList = JSON.parse(localStorage.getItem("whishList"))
        setWhishListQty(existingWhishList.length);
    }
    const addToCart = (product) => {
        const existingCart = JSON.parse(sessionStorage.getItem("cart")) || []
        const existingProductIndex = existingCart && existingCart.findIndex(item => item.product._id === product._id)
        if (existingProductIndex !== -1) {
            existingCart[existingProductIndex].quantity += cartNumber
        } else {
            existingCart.push({
                product: product,
                quantity: cartNumber
            })
        }
        sessionStorage.setItem("cart", JSON.stringify(existingCart))
        const storedCart = JSON.parse(sessionStorage.getItem("cart"))

        if (storedCart) {
            const subCart = storedCart.reduce((acc, product) => acc + product.quantity, 0)
            setCartQty(subCart)
        }
    }

    return (
        <div>
            {/* header start */}
            <div>
                <div className="header-Img" style={{ overflowX: "hidden" }}>
                    <div className="header-overlay">
                        {/* <Homebar /> */}
                        <div className="container">
                            <div className="row py-5 justify-content-between align-items-center">
                                <div className="col-md-6 head-text">
                                    <h1 className="text-uppercase" style={{ fontFamily: "revert" }}>Revamp Your Style <br /><span className="text-stroke"> Fearlessly</span></h1>
                                    <p>Step into the realm of high fashion with bold, statement-making styles. Unleash your unique flair with Galaxify's curated collection. Discover unparalleled elegance and make every moment a fashion statement.</p>
                                    <Link to="/shop"> <button className="btn btn-danger">Explore More</button> </Link>
                                </div>
                                <div className="col-md-4">
                                    <img src={headImg} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header end */}

            {/* our collections start */}
            <div className="container py-3">
                <h2 className="text-center">Collections</h2>
                <div className="row g-4">
                    {uniqueProducts && uniqueProducts.slice(0, 4).map((product, index) => {
                        return (
                            <div key={product._id} className="col-md-3 CatProduct" onClick={() => setProductTitle(product.title)}>
                                <div className="card">
                                    <Link to={`/category-product/${product.categoryId.category}`}>
                                        <div className="category-img" style={{ backgroundImage: `url(${product.images[0]})` }}>
                                            <div className="d-flex category-text align-items-center justify-content-center">
                                                <h6 className="text-center mt-2">{product.categoryId.category}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                    <div className="d-flex justify-content-center mt-3">
                        <Link to="/categories"><button className="btn btn-danger">View All</button></Link>
                    </div>
                </div>
            </div>
            {/* our collections  */}
            {/* about section start */}
            <div className="container py-4">
                <div className="row g-4 justify-content-between aboutSec">
                    <div className="col-md-6">
                        <h2>Our Story, Your Style</h2>
                        <p> Welcome to Galaxify, where fashion meets individuality. Our passion for trendsetting designs and commitment to quality has crafted a unique space for style enthusiasts. At Galaxify, we believe in empowering you to express your true self through fashion. We are dedicated to creating clothing that not only looks good but feels good, offering a perfect blend of comfort and style. Our team of innovative designers is constantly exploring new ideas and trends to bring you collections that are both contemporary and timeless. Join us on this fashion journey and experience the essence of true style.</p>
                        <div>
                            <li><i className="fas fa-angle-double-right me-2"></i>Handpicked designs that set you apart.</li>
                            <li><i className="fas fa-angle-double-right me-2"></i>Premium materials and meticulous attention to detail.</li>
                            <li><i className="fas fa-angle-double-right me-2"></i>Committed to eco-friendly practices for a better future.</li>
                            <li><i className="fas fa-angle-double-right me-2"></i>Your satisfaction is our priority, always.</li>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img src={aboutImg} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            {/* about section end */}
            {/* call to action start */}
            <div className="cal-bg">
                <div className="container-fluid call-to-action my-4">
                    <div className="container">
                        <div className="row align-items-center justify-content-center py-3">
                            <div className="col-md-7">
                                <h2 className="mb-2">DESIGN THAT TEMPT YOU!</h2>
                                <p> Explore Galaxify's diverse range of products crafted for style and innovation. From fashion-forward apparel to cutting-edge accessories, discover curated selections that redefine your lifestyle. Embrace quality and trendsetting design with Galaxify today.</p>
                                <Link to="/shop"><button className="btn btn-danger me-2">Shop Now</button></Link>
                                <Link to="/about"><button className="btn btn-danger">About Us</button></Link>
                            </div>
                            <div className="col-md-4">
                                <img src={callToImg} className="img-fluid" alt="" />
                            </div>
                        </div >
                    </div>
                </div >
            </div>
            {/* call to action end */}

            {/* new arrivals start */}
            <div className="container py-3">
                <h2 className="text-center">Latest Arrivals</h2>
                <div className="row g-4">
                    {Products && Products.slice(-4).map((product) => {
                        return <div key={product._id} className="col-md-3 latest-product">
                            <div className="card productCard">
                                <div className="product-logos">
                                    <h4 onClick={() => addToWhishList(product)} ><i className='fas fa-heart'></i></h4>
                                    <h4 onClick={() => addToCart(product)} className="mt-4"><i className='fas  fa-cart-plus'></i></h4>
                                </div>
                                <Link to={`/product-detail/${product.title.replace(/ /g, '_')}`}>
                                    <img src={product.images && product.images[0]} className="img-fluid product-image" alt="" />
                                    <h5 className="text-center">{product.categoryId && product.categoryId.category}</h5>
                                    <h6 className="text-center">{product.title.length > 35 ? product.title.slice(0, 35) + "..." : product.title}</h6>
                                    <h6 className="text-center">Rs.{product.price}</h6>
                                </Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {/* new arrivals end */}

            <Testimonial />
        </div >
    )
}