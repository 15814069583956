import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import Swal from 'sweetalert2'

export default function ShopManagerProfile() {
    const { setSignUser, signUser } = useContext(MyContext)
    const [signInUser, setSignInUser] = useState([])
    const [editUser, setEditUser] = useState([])
    const userDetail = async () => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/signUser/${signUser._id}`, {
            method: "GET"
        })
        const data = await res.json()
        setSignInUser(data)
    }

    useEffect(() => {
        userDetail()
    }, [])

    const edtUser = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/signUser/${id}`, {
            method: "GET"
        })
        const data = await res.json()
        console.log(data)
        setEditUser(data)
    }

    const updateUser = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });
        if (isConfirmed) {
            const { name, email, number, password } = editUser
            const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/UpdateUser/${editUser._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ name, email, number, password })
            })
            const data = await res.json()
            setSignUser(data)
            userDetail()
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }

    }

    const onchange = (e) => {
        setEditUser({ ...editUser, [e.target.name]: e.target.value })
    }

    return (
        <div>
            <h5><strong>Name:</strong> {signInUser.name}</h5>
            <h5><strong>Email:</strong> {signInUser.email}</h5>
            <h5><strong>Number:</strong> {signInUser.number}</h5>
            <h5><strong>Role:</strong> {signInUser.role}</h5>
            <button className='btn btn-outline-dark' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => edtUser(signInUser._id)}>Edit Profile</button>

            {/* modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Product Modal</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form >
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" name='name' value={editUser.name} onChange={onchange} />
                                <label htmlFor="email" className='mt-3'>Email</label>
                                <input type="text" className="form-control" name='email' value={editUser.email} onChange={onchange} />
                                <label htmlFor="number" className='mt-3'>Contact Number</label>
                                <input type="text" className="form-control" name='number' value={editUser.number} onChange={onchange} />
                                <label htmlFor="password" className='mt-3'>Password</label>
                                <input type="password" className="form-control" name='password' value={editUser.password} onChange={onchange} />
                                <button className='btn btn-primary mt-3' onClick={updateUser}>Update Profile</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
