import React, { useContext, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'

export default function Orders() {
    const { orders } = useContext(MyContext)

    const [order, setOrder] = useState([])
    const signUpUser = JSON.parse(sessionStorage.getItem("signUp"))

    const orderDetail = async (id) => {
        const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/orders/${id}`, {
            method: "GET"
        })
        const data = await res.json()

        setOrder(data)
    }

    function formatDate(dateString) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }

    const filteredOrders = orders.filter(order => order.email === signUpUser.email)
    return (
        <div >
            <div className='table-responsive'>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Order Amount</th>
                            <th scope="col">Order Number</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders && filteredOrders.map((order, index) => {
                            return <tr >
                                <td>{order.name}</td>
                                <td>{order.email}</td>
                                <td>{order.orderAmount}</td>
                                <td>{order.orderNumber}</td>
                                <td>{order.orderStatus}</td>
                                <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => orderDetail(order._id)}></i>
                                </td>
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
            {/* prodcut modal */}
            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Product Modal</h5> */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {order && order.length > 0 && (
                                <div>
                                    <h6><strong>Name:</strong> {order[0].orderId.name}</h6>
                                    <h6><strong>Address:</strong> {order[0].orderId.address}</h6>
                                    <h6><strong>Order Date:</strong> {formatDate(order[0].orderId.date)}</h6>
                                    <h6><strong>Order Number:</strong> {order[0].orderId.orderNumber}</h6>
                                    <h6><strong>Total Amount:</strong> {order[0].orderId.orderAmount}</h6>
                                    <h6><strong>Order Status:</strong> {order[0].orderId.orderStatus}</h6>
                                </div>
                            )}
                            <div className='table-responsive'>
                                <table className="table mt-5">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">SubTotal</th>
                                            <th scope="col">Added Information</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order && order.map((order, index) => {
                                            return <tr >
                                                <td>{order.productId && order.productId.title}</td>
                                                <td>{order.productId && order.productId.price}</td>
                                                <td>{order.quantity}</td>
                                                <td>{order.subTotal}</td>
                                                <td>{order.orderId.addInformation}</td>
                                            </tr>
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
