import React, { useContext, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import Swal from 'sweetalert2'

export default function AddCategory() {
  const { Categories, allCategories } = useContext(MyContext)
  const [category, setCategory] = useState("")
  const [getCategory, setGetCategory] = useState("")
  const [editCategory, setEditCategory] = useState("")
  const addCategory = async (e) => {
    e.preventDefault()
    const categoryError = document.getElementById("categoryError")
    let emptyFieldError = false
    if (!category) {
      categoryError.innerText = "Please enter category"
      emptyFieldError = true
    } else {
      categoryError.innerText = ""
    }
    if (emptyFieldError) {
      return;
    }
    const res = await fetch("https://galaxify-backend.vercel.app/api/auth/addCategory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ category })
    })
    const data = await res.json()
    if (data.message === "Category already exists") {
      categoryError.innerText = "Category already exists"
    }
    if (res.ok) {
      setCategory("")
    }
    allCategories()
  }
  const getCat = async (id) => {
    const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getCat/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    setGetCategory(data)
  }
  // const editCat = async (id) => {
  //   const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/getCat/${id}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   })
  //   const data = await res.json()
  //   setEditCategory(data)
  // }

  // const updateCat = async (e) => {
  //   e.preventDefault()
  //   const {category}  = editCategory
  //   console.log(category)
  //   const res = await fetch(`https://galaxify-backend.vercel.app/api/auth/updateCat/${editCategory._id}`,{
  //     method:"PUT",
  //     headers:{
  //       "Content-Type":"application/json"
  //     },
  //     body: JSON.stringify({category})
  //   })
  //   const data = await res.json()
  //   console.log(data)
  // }

  const delCat = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "This course category has been deleted successfully.",
          icon: "success",
        });
      }
      return result;
    });
    if (isConfirmed) {
      await fetch(`https://galaxify-backend.vercel.app/api/auth/delcat/${id}`, {
        method: "DELETE"
      })
      allCategories()
    }
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={addCategory}>
              <input type="text" className='form-control mt-3' placeholder='Add Category' name='category' value={category} onChange={(e) => setCategory(e.target.value)} />
              <div id="categoryError" className='text-danger' ></div>
              <button type="submit" className='btn btn-dark mt-3'>Add Category</button>
            </form>
          </div>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">category</th>
          </tr>
        </thead>
        <tbody>
          {Categories && Categories.map((product) => {
            return <tr>
              <td>{product.category}</td>
              <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => getCat(product._id)}></i>
                {/* <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => editCat(product._id)}></i> */}
                <i className="fa fa-trash" onClick={() => delCat(product._id)}></i>
              </td>
            </tr>
          })}
        </tbody>
      </table>

      {/* view modal */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Category View Modal</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form encType="multipart/form-data">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <input className="form-control" value={getCategory.category} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Edit modal */}
      {/* <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Category View Modal</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <input className="form-control" name='category' value={editCategory.category} onChange={(e) => setEditCategory(e.target.value)} />
                  </div>
                </div>
                <button className='btn btn-primary mt-3' onClick={updateCat}>Update Category</button>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}
