import React, { useContext } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import { Link } from 'react-router-dom'

export default function CategoryProduct() {
    const { ProductByTitle, Products, setCartQty, setWhishListQty } = useContext(MyContext)
    const cartNumber = 1
    const filteredProducts = Products && ProductByTitle && ProductByTitle.categoryId && Products.filter(product => product.categoryId.category === ProductByTitle.categoryId.category)

    const addToWhishList = (product) => {
        const WhishList = JSON.parse(localStorage.getItem("whishList")) || []
        WhishList.push(product)
        localStorage.setItem("whishList", JSON.stringify(WhishList))

        const existingWhishList = JSON.parse(localStorage.getItem("whishList"))
        setWhishListQty(existingWhishList.length);
    }

    const addToCart = (product) => {
        const existingCart = JSON.parse(sessionStorage.getItem("cart")) || []
        const existingProductIndex = existingCart.findIndex(item => item.product._id === product._id)
        if (existingProductIndex !== -1) {
            existingCart[existingProductIndex].quantity += cartNumber
        } else {
            existingCart.push({
                product: product,
                quantity: cartNumber
            })
        }
        sessionStorage.setItem("cart", JSON.stringify(existingCart))
        const storedCart = JSON.parse(sessionStorage.getItem("cart"))
        console.log(storedCart);
        if (storedCart) {
            const subCart = storedCart.reduce((acc, product) => acc + product.quantity, 0)
            setCartQty(subCart)
        }

    }
    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Products</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container py-4">
                <div className="row">
                    {filteredProducts && filteredProducts.map((product) => {
                        return <div className="col-md-3 categoryProduct latest-product">
                            <div className="card productCard">
                                <div className="product-logos">
                                    <h4 onClick={() => addToWhishList(product)} ><i className='fas fa-heart'></i></h4>
                                    <h4 onClick={() => addToCart(product)} className='mt-4'><i className='fas  fa-cart-plus'></i></h4>
                                </div>
                                <Link to={`/product-detail/${product.title.replace(/ /g, '_')}`}>
                                    <img src={product.images[0]} className="img-fluid product-image" alt="" />
                                    <h5 className="text-center">{product.categoryId && product.categoryId.category}</h5>
                                    <h6 className="text-center">{product.title.length > 35 ? product.title.slice(0, 35) + "..." : product.title}</h6>
                                    <h6 className="text-center">Rs.{product.price}</h6>
                                </Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}
