import React from "react";
import logoImg from "../img/galaxify-logo1.png"
import { Link } from "react-router-dom";

export default function AdminNavbar() {
    const user = JSON.parse(sessionStorage.getItem("signUp"))
    return (
        <div>
            <nav className="navbar navbar-expand-lg home-bar">
                <div className="container-fluid px-4">
                    <div className="navbar-brand"><img src={logoImg} alt="" style={{ width: "200px" }} /></div>

                    <div className="d-flex justify-content-end">
                        <li className="nav-link text-white" data-bs-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample">
                            <i className="fas fa-user" style={{ fontSize: "30px" }}></i>
                        </li>
                    </div>
                </div>
            </nav>

            <div className="container-fluid">
                <div className="row d-flex justify-content-end">
                    <div className="col-md-2">
                        <div className="collapse profile-set" id="collapseExample">
                            <div className="card card-body">
                                <h6 className="text-center">{user.name}</h6>
                                <div className="d-flex justify-content-center">
                                    <h3 className="text-center">{user.name.charAt(0)}</h3>
                                </div>
                                <p className="text-center">{user.number}</p>
                                <h5 className="text-cenetr">{user.email}</h5>
                                <h6 className="text-center"> <Link to="/">Log Out</Link></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}