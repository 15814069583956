import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../img/galaxify-logo1.png"
import MyContext from "../ContextApi/MyCOntext";

export default function Navbar() {
    const { cartQty, whishListQty, setWhishListQty } = useContext(MyContext)

    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true)
    const [isScroll, setIsScroll] = useState(false)
    const [collpaseQty, setCollapseQty] = useState(false)
    const [prevCartQty, setPreviousCartQty] = useState(cartQty)
    const location = useLocation()
    const navRef = useRef(null)
    useEffect(() => {
        const handleNavLink = (e) => {
            if (navRef.current && !navRef.current.contains(e.target)) {
                setIsNavbarCollapsed(true)
            }
        }

        const handleScroll = () => {
            if (window.scrollY) {
                setIsScroll("scrolled")
            } else {
                setIsScroll("")
            }
        }
        document.addEventListener("click", handleNavLink)
        window.addEventListener("scroll", handleScroll)
        return () => {
            document.removeEventListener("click", handleNavLink)
            window.removeEventListener("scroll", handleScroll)
        }
    })

    useEffect(() => {
        if (cartQty !== prevCartQty && cartQty !== 0) {
            setCollapseQty(true)
            setTimeout(() => {
                setCollapseQty(false)
            }, 5000);
            setPreviousCartQty(cartQty)
        }
    }, [cartQty, prevCartQty])

    if (location.pathname === "/admin-panel/users" || location.pathname === "/admin-panel/add-category" || location.pathname === "/admin-panel/add-product" || location.pathname === "/admin-panel/orders" || location.pathname === "/shop-manager/all-products" || location.pathname === "/shop-manager/add-product" || location.pathname === "/order-detail") {
        return null;
    }

    const handleNavbar = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed)
    }
    const closeNavbar = () => {
        if (window.innerWidth <= 768) {
            setIsNavbarCollapsed(true)
        }
    }
    return (
        <div>
            <nav className={`navbar navbar-expand-lg navbar-light home-bar ${isScroll}`} ref={navRef}>
                <div className="container-fluid px-4">
                    <Link className="navbar-brand" to="/"><img src={logoImg} alt="" className="img-fluid" style={{ width: "180px" }} /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavbarCollapsed} onClick={handleNavbar} aria-label="Toggle navigation" style={{ backgroundColor: "white" }}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`} id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link text-white" aria-current="page" to="/" onClick={closeNavbar}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/about" onClick={closeNavbar}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/categories" onClick={closeNavbar}>Collections</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" aria-current="page" to="/shop" onClick={closeNavbar}>Shop</Link>
                            </li>
                            <li className="nav-item">

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/contact-us" onClick={closeNavbar}>Contact</Link>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center">
                            <Link className="nav-link" aria-current="page" to="/order-tracking" onClick={closeNavbar}><button className="btn" style={{ backgroundColor: "orange" }}> Order Tracking</button></Link>
                            <Link className="nav-link" to="/whish-list" onClick={() => setWhishListQty(0)}><i className="fas fa-heart" onClick={closeNavbar}></i>
                                {whishListQty !== 0 && (
                                    <span className="badge bg-primary rounded-pill">{whishListQty}</span>
                                )}
                            </Link>
                            <Link className="nav-link" to="/cart-product">
                                <i className="fas fa-shopping-cart cartIcon" onClick={closeNavbar}></i>
                                {cartQty > 0 && (
                                    <span className="badge bg-primary rounded-pill">{cartQty}</span>
                                )}
                            </Link>
                            <Link className="nav-link" to="/signIn" onClick={closeNavbar}><i className="fas fa-user"></i></Link>
                        </div>
                    </div>
                </div>
                <div className={`collapse profileSet ${collpaseQty ? "show" : ""}`} id="collapseExample">
                    <div className="card card-body">
                        <h6> Cart Updated Successfully</h6>
                    </div>
                </div>
            </nav >

        </div>
    )
}