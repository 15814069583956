import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import { Link } from 'react-router-dom'

export default function Collections() {
    const { Products, setProductTitle } = useContext(MyContext)
    const [uniqueProducts, setUniqueProducts] = useState([])
    useEffect(() => {
        const categories = new Set()
        const uniqueCategories = []
        const extractUnqiueProducts = (Products) => {
            Products.forEach(product => {
                if (product.categoryId && !categories.has(product.categoryId.category)) {
                    categories.add(product.categoryId.category);
                    uniqueCategories.push(product);
                }
            });
            return uniqueCategories;
        };

        setUniqueProducts(extractUnqiueProducts(Products))
    }, [Products])
    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Our Collections</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container py-3">
                {/* <h2 className="text-center">Our Categories</h2> */}
                <div className="row g-4">

                    {uniqueProducts && uniqueProducts.map((product, index) => {
                        return (
                            <div key={product._id} className="col-md-3 CatProduct" onClick={() => setProductTitle(product.title)}>
                                <div className="card">
                                    <Link to={`/category-product/${product.categoryId.category}`}>
                                        <div className="category-img" style={{ backgroundImage: `url(${product.images[0]})` }}>
                                            <div className="d-flex category-text align-items-center justify-content-center">
                                                <h6 className="text-center mt-2">{product.categoryId.category}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}
