import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MyContext from '../ContextApi/MyCOntext'
import emailjs from '@emailjs/browser'

export default function CheckOut() {
    const { setOrderId, setCartQty } = useContext(MyContext)
    const navigate = useNavigate()
    const [Prodcuts, setProducts] = useState([])
    const [billings, setBillings] = useState({
        name: "",
        email: "",
        number: "",
        country: "",
        address: "",
        city: "",
        postCode: "",
        addInformation: "",
        orderStatus: "Pending"
    })

    useEffect(() => {
        const storedCart = JSON.parse(sessionStorage.getItem("cart"))
        if (storedCart) {
            setProducts(storedCart)
        }
    }, [])

    const producttotal = Prodcuts.reduce((acc, product) => acc + (product.product.price * product.quantity), 0);

    const billingsDetail = async () => {
        const { name, email, number, country, city, addInformation, address, postCode, orderStatus } = billings
        console.log(orderStatus);
        const saveCartProduct = Prodcuts.map(product => ({
            productId: product.product._id,
            quantity: product.quantity,
            subTotal: product.product.price * product.quantity,
        }));
        const orderAmount = producttotal
        const countryError = document.getElementById("countryError")
        const addressError = document.getElementById("addressError")
        const cityError = document.getElementById("cityError")
        const postCodeError = document.getElementById("postCodeError")
        let emptyFieldError = false
        if (!country) {
            countryError.innerText = "Please enter your country"
            emptyFieldError = true
        } else {
            countryError.innerText = " "
        }
        if (!address) {
            addressError.innerText = "Please enter your address"
            emptyFieldError = true
        } else {
            addressError.innerText = " "
        }
        if (!city) {
            cityError.innerText = "Please enter your city"
            emptyFieldError = true
        } else {
            cityError.innerText = " "
        }
        if (!postCode) {
            postCodeError.innerText = "Please enter your post code"
            emptyFieldError = true
        } else {
            postCodeError.innerText = " "
        }
        if (emptyFieldError) {
            return;
        }
        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/placeOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, email, number, country, city, addInformation, address, postCode, orderAmount, cartProducts: saveCartProduct, orderStatus })
        })
        const data = await res.json()
        console.log(data.placeOrder.orderStatus);
        if (res.ok) {
            setBillings({
                name: "",
                email: "",
                number: "",
                country: "",
                address: "",
                city: "",
                postCode: "",
                addInformation: ""
            })
            const mailData = ({
                name: data.placeOrder.name,
                email: data.placeOrder.email,
                number: data.placeOrder.number,
                address: data.placeOrder.address,
                orderAmount: data.placeOrder.orderAmount,
                orderStatus: data.placeOrder.orderStatus,
                orderNumber: data.placeOrder.orderNumber
            })
            sendEmail(mailData)
            setOrderId(data.placeOrder._id)
            navigate("/order-complete")
            sessionStorage.removeItem("cart")
        }
    }

    const onchange = (e) => {
        setBillings({ ...billings, [e.target.name]: e.target.value })
    }
    const sendEmail = (mailData) => {
        emailjs.send("service_zczyrkh", "template_mvn4zoq", mailData, "PmiNIES8R8fSA_r1R")
    }
    const checkOutProduct = () => {
        billingsDetail()
        setCartQty(0)
    }
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 py-4">
                        <h4>Billing Details</h4>
                        <form className='py-3'>
                            <label htmlFor="name">Your Name</label>
                            <input type="text" name='name' value={billings.name} className='form-control' onChange={onchange} />
                            <label htmlFor="Country" className='mt-3'>Country/Region</label>
                            <input type="text" className='form-control' name='country' value={billings.country} onChange={onchange} />
                            <div id="countryError" className='text-danger'></div>
                            <label htmlFor="address" className='mt-3'>Address</label>
                            <input type="text" className='form-control' name='address' value={billings.address} onChange={onchange} />
                            <div id="addressError" className='text-danger'></div>
                            <label htmlFor="city" className='mt-3'>Town/City</label>
                            <input type="text" className='form-control' name='city' value={billings.city} onChange={onchange} />
                            <div id="cityError" className='text-danger'></div>
                            <label htmlFor="postcode" className='mt-3'>Postcode/ZIP</label>
                            <input type="text" className='form-control' name='postCode' value={billings.postCode} onChange={onchange} />
                            <div id="postCodeError" className='text-danger'></div>
                            <label htmlFor="number" className='mt-3'>Contact Number</label>
                            <input type="tel" className='form-control' name='number' value={billings.number} onChange={onchange} />
                            <label htmlFor="email" className='mt-3'>Email</label>
                            <input type="email" className='form-control' name='email' value={billings.email} onChange={onchange} />
                            <h4 className='mt-3'>Additional Information</h4>
                            <textarea rows="7" className='form-control' name='addInformation' value={billings.addInformation} onChange={onchange}></textarea>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <h3 className='py-4'>Order Summary</h3>
                        <section className='p-4' style={{ backgroundColor: "rgba(229,229,229,.4)" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Prodcuts.map((product) => (
                                        <tr key={product.product.id}>
                                            <td><img src={product.product.images[0]} className='img-fluid' style={{ height: "70px", width: "70px" }} alt="" /> {product.product.title}</td>

                                            <td>Rs.{product.product.price}</td>
                                            <td>{product.quantity}</td>
                                            <td>Rs.{product.product.price * product.quantity}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className='d-flex justify-content-end'>
                                <h3 style={{ marginRight: "20px" }}>Total</h3>
                                <h3 className='text-danger'>{producttotal}</h3>
                            </div>

                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="orderStatus" value="onHold" checked={billings.orderStatus === "onHold"} id="flexRadioDefault1" onChange={onchange} />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1" style={{ fontWeight: "bold" }}>
                                        Bank Transfer
                                    </label>
                                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account. </p>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="orderStatus" value="Pending" checked={billings.orderStatus === "Pending"} id="flexRadioDefault2" onChange={onchange} />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ fontWeight: "bold" }}>
                                        Cash On Delivery
                                    </label>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end mt-3">
                                <button className='btn btn-danger' onClick={checkOutProduct}>Place Order</button>
                            </div>
                        </section>
                    </div>

                </div>
            </div>
        </div>
    )
}
