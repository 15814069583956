import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../ContextApi/MyCOntext'
import { Link } from 'react-router-dom';

export default function OrderComplete() {
    const { order } = useContext(MyContext)

    const [onHoldOrder, setOnHoldOrder] = useState([])
    function formatDate(dateString) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }
    useEffect(() => {
        const onHoldOrders = order && order.filter(order => order.orderId && order.orderId.orderStatus === "onHold")
        setOnHoldOrder(onHoldOrders)
    }, [order])
    return (
        <div className='container py-5'>
            <div className="d-flex justify-content-center">
                <h1 className='mb-5 text-center' style={{ borderBottom: "4px solid #BB2D3B", display: "inline-block" }}>Thank You For Shopping With Galaxify</h1>
            </div>
            {onHoldOrder && onHoldOrder.length > 0 ? (
                <div className="row">
                    <div className="col-md-6 mb-5">
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    Account Name: Adil Masood <br />
                                    Account number: 14077900243203 <br />
                                    Bank name: HBL <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ""}
            <div className="row justify-content-between">

                <div className="col-md-7">
                    <h3>Order Detail</h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">SubTotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order && order.map((order, index) => {
                                return <tr>
                                    <td>{order.productId.title}</td>
                                    <td>{order.productId.price}</td>
                                    <td>{order.quantity}</td>
                                    <td>{order.subTotal}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-5">
                    {order && order.length > 0 && (
                        <div className='p-4' style={{ backgroundColor: "rgba(229,229,229,.4)" }}>
                            <div className='d-flex align-items-center'>
                                <i className='fa fa-check me-2 p-1' style={{ backgroundColor: "red", borderRadius: "100px" }}></i>
                                <h5>Order Completed Successfully</h5>
                            </div>
                            <h6><strong>Name:</strong> {order[0].orderId.name}</h6>
                            <h6><strong>Address:</strong> {order[0].orderId.address}</h6>
                            <h6><strong>Order Date:</strong> {formatDate(order[0].orderId.date)}</h6>
                            <h6><strong>Order Number:</strong> {order[0].orderId.orderNumber}</h6>
                            <h6><strong>Order Amount:</strong> {order[0].orderId.orderAmount}</h6>
                            <h6><strong>Order Status:</strong> {order[0].orderId.orderStatus}</h6>
                        </div>
                    )}
                </div>
            </div>
            <Link to="/shop"> <button className='btn btn-danger'><i className='fas fa-arrow-left'></i> Continue Shopping</button> </Link>
        </div>
    )
}
