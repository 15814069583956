import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function AdminLogin() {

    const navigate = useNavigate()
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    const signInFunc = async (e) => {
        e.preventDefault()
        const emailError = document.getElementById("emailError")
        const passwordError = document.getElementById("passwordError")
        const { email, password } = user
        let emptyFieldError = false

        if (!email) {
            emailError.innerText = "Please enter email"
            emptyFieldError = true
        } else {
            emailError.innerText = ""
        }

        if (!password) {
            passwordError.innerText = "Please enter password"
            emptyFieldError = true
        } else {
            passwordError.innerText = ""
        }

        if (emptyFieldError) {
            return;
        }

        const res = await fetch("https://galaxify-backend.vercel.app/api/auth/adminSignIn", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, password })
        })
        const data = await res.json()
        const logInError = document.getElementById("logInError")
        if (data.message === "Invalid Credentials") {
            logInError.innerText = "Invalid Credentials"
        }
        if (res.ok) {
            sessionStorage.setItem("adminSignUp", JSON.stringify(data))
            navigate("/admin-panel")
        }

    }

    const onchange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Admin Sign In</h1>
                </div>
            </div>
            {/* page header end */}
            <div className="container py-3">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div id="logInError" className='text-danger text-center' ></div>
                        <form onSubmit={signInFunc}>

                            <input type="email" className='form-control mt-3' placeholder='Email' name='email' value={user.email} onChange={onchange} />
                            <div id="emailError" className='text-danger' ></div>

                            <input type="password" className='form-control mt-3' placeholder='Password' name='password' value={user.password} onChange={onchange} />
                            <div id="passwordError" className='text-danger' ></div>

                            <div className='d-flex justify-content-center mt-3'>
                                <button type='submit' className='btn btn-danger'>Sign In</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
