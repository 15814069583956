import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser"

export default function Contact() {
    const formRef = useRef()
    const [successMessage, setSuccessMessage] = useState("")
    const sendEmail = (e) => {
        e.preventDefault()
        const form = formRef.current
        const name = form.name.value
        const email = form.email.value
        const subject = form.subject.value
        const message = form.message.value
        const nameError = document.getElementById("nameError")
        const emailError = document.getElementById("emailError")
        const messageError = document.getElementById("messageError")
        let hasError = false;
        if (!name) {
            nameError.innerText = "please enter name"
            hasError = true
        } else {
            nameError.innerText = ""
        }
        if (!email) {
            emailError.innerText = "please enter email"
            hasError = true
        } else {
            emailError.innerText = ""
        }
        if (!message) {
            messageError.innerText = "please enter message"
            hasError = true
        } else {
            messageError.innerText = ""
        }
        if (hasError) {
            return;
        }
        const emailData = {
            name,
            email,
            subject,
            message
        }
        emailjs.send("service_zczyrkh", "template_w569o2g", emailData, "PmiNIES8R8fSA_r1R")
            .then(res => {
                form.reset()
                setSuccessMessage("email send successfully")
                setTimeout(() => {
                    setSuccessMessage("")
                }, 4000);
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            {/* page header start */}
            <div className='all-Header'>
                <div className="all-overlay">
                    <h1 className='text-white text-center py-6'>Contact Us</h1>
                </div>
            </div>
            {/* page header end */}

            {/* contact start */}
            <div className="container contact py-5">
                <h3 className="text-center">Get in touch</h3>
                <p className="text-center">Want to get in touch? We’d love to hear from you. Here’s how you can reach us…</p>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2 className="text-center pt-4">Ask A Question</h2>
                        {successMessage && (
                            <p className="text-center py-2" style={{ backgroundColor: "#d6fbd6" }}>{successMessage}</p>
                        )}
                        <form onSubmit={sendEmail} ref={formRef}>
                            <input type="text" className="form-control" placeholder="Your Name" name="name" />
                            <div className="text-danger" id="nameError"></div>
                            <input type="email" className="form-control mt-3" placeholder="Your Email" name="email" />
                            <div className="text-danger" id="emailError"></div>
                            <input type="text" className="form-control mt-3" placeholder="Your Subject" name="subject" />
                            <textarea name="message" id="message" cols="30" rows="10" className="form-control mt-3" placeholder="Your Message"></textarea>
                            <div className="text-danger" id="messageError"></div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-danger mt-3" type="submit">Send Now</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 pt-4">
                        <div className="row contactInfo g-4">
                            <div className="col-md-12">
                                <div style={{ backgroundColor: "rgba(235, 236, 236, 1)" }}>
                                    <div className="p-3">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="fas fa-map-marker-alt fa-2x"></i>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <h6 className="mt-3">Mahir Industries,Main GondlanWala Road, Gujranwala, Punjab, Pakistan.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div style={{ backgroundColor: "rgba(235, 236, 236, 1)" }}>
                                    <div className="p-3">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="fas fa-phone fa-2x"></i>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <h6 className="mt-3">+92-55-4236700</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div style={{ backgroundColor: "rgba(235, 236, 236, 1)" }}>
                                    <div className="p-3">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="fas fa-mobile-alt fa-2x"></i>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <h6 className="mt-3">+92-310-7777899</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3" >
                                <div className="p-3" style={{ backgroundColor: "rgba(235, 236, 236, 1)" }}>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <i className="fas fa-envelope fa-2x"></i>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <h6 className="mt-3">info@galaxify.com</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact end */}

        </div>
    );
}
